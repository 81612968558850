import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '185px', // Adjust height as necessary
};

const ItemLocation = ({ lat, lng }) => {
  const [map, setMap] = useState(null);

  // Load the Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Use your own Google Maps API key here
  });

  const center = {
    lat: lat || 28.6139, // Default to a central location if no lat/lng is provided
    lng: lng || 77.209,
  };

  const onLoad = (map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const OPTIONS = {
     minZoom: 4,
     maxZoom: 20,
     fullscreenControl: true,
     streetViewControl: true,
     mapTypeControl: true,
     showsMyLocationButton: true,
     showsUserLocation: true,
   };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={OPTIONS}
      center={center}
      zoom={14} // Adjust the zoom level as necessary
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default ItemLocation;
