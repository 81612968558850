/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  BagIconLight,
  CrossWhiteIcon,
  EyeGreyIcon,
  FacebookBlueIcon,
  GooglePlusIcon,
  KeyGreyIcon,
  NavBarLogo,
  SearchIcon,
  UserGreyIcon,
} from "../../SvgIcons/allIcons";
import { Link, NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button, Col, Row, Tab } from "react-bootstrap";
import {
  ChangeCurrentRole,
  CheckUserLogin,
  getCategory,
  logOut,
} from "../../Services/APIServices";
import { useNavigate } from "react-router-dom/dist";
import showNotification from "../../Services/NotificationService";
import { useDispatch, useSelector } from "react-redux";
import { isLogin, isLogout } from "../../Redux/LoginSlice";
import { LENDER, RENTER } from "../../globals/constants";
import { searchQuery } from "../../Redux/searchSlice";
import { getCart } from "../../Redux/cartListSlice";
import Loader from "../Loader/Loader";
import { imageBase } from "../../globals/Config";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";

const RenterHeader = (props) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const [defaultCategoryId, setDefaultCategoryId] = useState("");
  const [search, setSearch] = useState("");
  const { host, hostname, href, origin, pathname, port, protocol } =
    window.location;

  let currentpage = "";

  useEffect(() => {
    dispatch(getCart());
  }, []);
  const products = useSelector((state) => state?.cartData?.cart);

  if (pathname == "/renter/home") {
    currentpage = "home";
  } else if (pathname == "/renter/how-it-works") {
    currentpage = "how-it-works";
  } else if (pathname == "/renter/category-map-view") {
    currentpage = "marketplace";
  } else if (pathname == "/message") {
    currentpage = "message";
  }

  const logOutHandler = (e) => {
    e.preventDefault();
    logOut().then((resp) => {
      if (resp?.status === 200) {
        localStorage.clear();
        dispatch(isLogout());
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (token) {
      catagoryList();
      checkUser();
    }
  }, [token]);

  const checkUser = () => {
    CheckUserLogin().then((resp) => {
      if (resp?.status === 200) {
        dispatch(isLogin(resp?.data?.data));
      } else {
        dispatch(isLogout());
        localStorage.clear();
        navigate("/");
      }
    });
  };

  const [list, setList] = useState([]);
  const catagoryList = () => {
    getCategory().then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.data);
        setDefaultCategoryId(resp?.data?.data[0].id);
      }
    });
  };

  const handleViewCategory = (data) => {
    navigate(`/renter/category-map-view?id=${data?.id}&t=${data?.title}`);
  };

  const currentRole = useSelector(
    (state) => state?.Login?.userDetails?.role_id
  );
  const [role, setRole] = useState(currentRole);

  const handleRoleChange = (e) => {
    let formData = new FormData();
    formData.append("role_id", LENDER);
    setLoader(true);
    ChangeCurrentRole(formData).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        dispatch(isLogin(resp?.data?.data));
        if (resp?.data?.data?.role_id === RENTER) {
          navigate("/renter/home");
        }
        if (resp?.data?.data?.role_id === LENDER) {
          navigate("/lender/listing");
        }
        setRole(e.target.value);
      }
    });
  };

  const removeSearch = () => {
    dispatch(searchQuery(""));
    setSearch("");
  };

  const handleSearch = () => {
    dispatch(searchQuery(search));
    navigate("/catagory-mapping");
  };

  return (
    <>
      <Navbar expand="lg" className="logout-navbar">
        <Container>
          <Navbar.Brand>
            <NavLink to={`/renter/home`}>
              <NavBarLogo />
            </NavLink>
          </Navbar.Brand>
          <Form className="d-flex search-form">
            <Form.Control
              type="text"
              placeholder="Search"
              className="Search"
              aria-label="Search"
              value={search}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value == "") {
                  dispatch(searchQuery(""));
                  setSearch("");
                }
              }}
            />
            <span className="search-icon" onClick={handleSearch}>
              <SearchIcon />
            </span>
            {search.length ? (
              <span className="header-cross" onClick={removeSearch}>
                <CrossWhiteIcon />
              </span>
            ) : (
              ""
            )}
          </Form>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavLink
                to="/renter/home"
                onClick={() => removeSearch()}
                className="nav-link"
              >
                Home
              </NavLink>
              <NavLink
                to="/renter/how-it-works"
                onClick={() => removeSearch()}
                className="nav-link"
              >
                How it works
              </NavLink>
              <NavLink
                to="/renter/message"
                onClick={() => removeSearch()}
                className="nav-link"
              >
                Messages
              </NavLink>
              <NavLink
                onClick={() => removeSearch()}
                to={"/renter/category-map-view?id="}
                className="nav-link"
              >
                Marketplace
              </NavLink>

              <NavDropdown
                href="#action5"
                title="Categories"
                id="navbarScrollingDropdown"
                className={currentpage == "marketplace" ? "active" : ""}
              >
                {list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <NavDropdown.Item
                        key={index}
                        onClick={() => handleViewCategory(data)}
                      >
                        {data?.title}
                      </NavDropdown.Item>
                    );
                  })
                ) : (
                  <>No Categories Available</>
                )}
              </NavDropdown>

              <Nav.Link
                as={Link}
                to="/renter/cart"
                className="user-menu nav-link"
              >
                <BagIconLight />
                {products?.length > 0 && (
                  <sup className="item-count">{products?.length}</sup>
                )}
              </Nav.Link>
              <span className="user-img">
                {userDetails?.profile_pic ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    alt="..."
                    className="avatar"
                    src={
                      userDetails?.profile_pic
                        ? imageBase + userDetails?.profile_pic
                        : require("../../assets/images/DummyImg.png")
                    }
                  />
                ) : (
                  <DefaultProfileImg userDetails={userDetails} />
                )}
              </span>
              <NavDropdown
                className="user-menu"
                title={userDetails?.first_name}
                id="navbarScrollingDropdown"
              >
                {/* <NavDropdown.Item
                  href="/renter/profile"
                  className={currentpage == "profile" ? "active" : ""}
                >
                  Profile
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  href="/renter/profile"
                  className={currentpage == "profile" ? "active" : ""}
                >
                  Your account
                </NavDropdown.Item>
                <NavDropdown.Item href="/renter/my-rental">
                  Your orders
                </NavDropdown.Item>
                <NavDropdown.Item value={LENDER} onClick={handleRoleChange}>
                  Switch to lender
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={(e) => {
                    logOutHandler(e);
                  }}
                >
                  Sign out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar className="mobile-nav d-none">
        <Container>
          <Navbar.Brand href="/renter/home">
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 279"
              width="1000"
              height="279"
            >
              <path
                id="Layer"
                fillRule="evenodd"
                fill="#16ff96"
                d="m125.5 31c8.6 0 15.5-6.9 15.5-15.5 0-8.6-6.9-15.5-15.5-15.5-7.8 0-14.3 5.8-15.3 13.3-24.7 4-47.9 14.8-66.8 31.3-22.5 19.6-37.8 46.2-43.4 75.5l6.6 1.3c5.3-27.9 19.8-53.1 41.2-71.7 17.8-15.6 39.6-25.9 62.9-29.7 1.9 6.4 7.8 11 14.8 11zm137 88.5c-5.7-29.3-21.1-55.8-43.7-75.3l-4.4 5.1c21.5 18.5 36.1 43.7 41.6 71.5 4.6 23.4 2.5 47.6-6 69.7q-2-0.6-4.3-0.6c-8.6 0-15.5 6.9-15.5 15.5 0 8.5 6.9 15.5 15.5 15.5 8.5 0 15.5-7 15.5-15.5 0-4.6-2-8.8-5.2-11.6 9.1-23.6 11.4-49.3 6.5-74.3zm-174.4 152.3c28.2 9.7 58.9 9.6 87.1-0.2l-2.2-6.3c-26.8 9.3-55.9 9.4-82.8 0.2-23.1-7.9-43.4-22.3-58.5-41.3 2.9-2.8 4.7-6.8 4.7-11.1 0-8.5-7-15.5-15.5-15.5-8.6 0-15.5 7-15.5 15.5 0 8.6 6.9 15.5 15.5 15.5 1.8 0 3.5-0.3 5.1-0.9 16 20.4 37.5 35.7 62.1 44.1z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m336.9 78.4c0 21.8 12.8 36 37.5 41.6l24.9 5.7c14.4 3.3 20.9 10.9 20.9 22.8 0 15.1-12.8 24.5-32.8 24.5-19.1 0-31.2-9.2-31.7-23.7h-20.9c1.3 26 21.8 42.1 52.6 42.1 32.2 0 53.3-17.4 53.3-44.6 0-21.3-12.5-34.7-37.2-40.4l-24.9-5.8c-14.3-3.4-21.2-10.7-21.2-22.8 0-14.9 12.4-24.5 31.4-24.5 17 0 27.6 8.8 28.7 23.8h20.7c-1.7-26.1-20.3-42.2-49.2-42.2-31.2 0-52.1 17.6-52.1 43.5z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m482.8 188.7v-55.7c0-17.6 10.3-31.4 29.3-31.4 14.7 0 24.5 8.4 24.5 29.1v58.2h19.7v-62.6c0-26.1-12.6-42.7-38.7-42.7-15.9 0-28.3 7.6-34.8 18.7v-67.4h-19.6v153.8z"
              />
              <path
                id="Layer"
                fillRule="evenodd"
                fill="#ffffff"
                d="m578.5 160.4c0-18.8 14-30.5 38.1-32.4l32.2-2.5v-2.9c0-17.2-10.2-23.3-24-23.3-16.6 0-26 7.3-26 19.7h-17.1c0-21.4 17.5-35.4 43.9-35.4 25.3 0 42.5 13.4 42.5 41v64.3h-16.7l-2.1-16.5c-5.3 11.7-19.3 19-35.8 19-22 0-35-12.4-35-31zm70.6-15.7v-5.4l-26.2 2.1c-17.4 1.7-24.5 8.4-24.5 18.4 0 10.9 8 16.3 20.7 16.3 18.6 0 30-12.1 30-31.4z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m705.8 188.9l25.1-37.5 25.6 37.5h22.8l-35-50.7 35.6-51.6h-22.4l-25.3 38-26-38h-22.8l35.6 51.6-35.8 50.7z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m805.9 62.3c-7.1 0-12.9-5.8-12.9-13 0-7.2 5.8-13 12.9-13 7.2 0 13 5.8 13 13 0 7.2-5.8 13-13 13zm-9.6 24.3h19.7v102.3h-19.7z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m833.7 86.6v16.5h15v85.8h19.7v-85.8h24.3v-16.5h-24.3v-16.4c0-11.1 3.4-17.5 16.1-17.5h8.8v-17c-4-0.6-8.3-0.8-12.1-0.8-18.6 0-32.5 10.2-32.5 35.3v16.4z"
              />
              <path
                id="Layer"
                fill="#ffffff"
                d="m901.1 234.7c5.3 1.3 10.7 2.1 17.2 2.1 15.7 0 26-7.3 32.9-24.9l48.3-125.3h-20.3l-30.3 82.4-29.8-82.4h-20.7l41.3 107.7-3.6 9.9c-4.8 12.9-12.3 14.4-21.8 14.4h-13.2z"
              />
            </svg>
          </Navbar.Brand>
          <div className="d-flex align-tems-center gap-3">
            <Nav.Link href="/renter/cart" className="user-menu mt-2">
              <BagIconLight />
              {products?.length > 0 && (
                <sup className="item-count">{products?.length}</sup>
              )}
            </Nav.Link>
            <span className="user-img">
              {userDetails?.profile_pic ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  alt="..."
                  className="avatar"
                  src={
                    userDetails?.profile_pic
                      ? imageBase + userDetails?.profile_pic
                      : require("../../assets/images/DummyImg.png")
                  }
                />
              ) : (
                <DefaultProfileImg userDetails={userDetails} />
              )}
            </span>
          </div>
        </Container>
      </Navbar>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="header-area">
            <Link>
              <img src={require("../../assets/images/logo.png")} alt="" />
            </Link>
            <Modal.Title>Welcome</Modal.Title>
            <Tab.Container id="left-tabs-example" defaultActiveKey="login">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="login">I’m a new user</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="register">I have an account</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <div className="middle-area">
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        <Form>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="Form.ControlInput1"
                          >
                            <div className="user-icon">
                              <UserGreyIcon />
                            </div>
                            <Form.Control
                              type="email"
                              placeholder="Username/Email"
                              className="email"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="Form.ControlInput2"
                          >
                            <div className="key-icon">
                              <KeyGreyIcon />
                            </div>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              className="password"
                            />
                            <div className="eye-icon">
                              <EyeGreyIcon />
                            </div>
                          </Form.Group>
                        </Form>
                        <div className="d-flex align-items-center justify-content-between ">
                          <Button className="btn-theme">Login</Button>
                          <Link href="" className="text-underline">
                            Forgot Password?
                          </Link>
                        </div>
                        <h5>OR</h5>
                        <Link href="" className="btn w-100 btn-social">
                          <FacebookBlueIcon /> Continue with Facebook
                        </Link>
                        <Link href="" className="btn w-100 btn-social mt-2">
                          <GooglePlusIcon />
                          Continue with Google
                        </Link>
                      </Tab.Pane>
                      <Tab.Pane eventKey="register">
                        <Form>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="Form.ControlInput1"
                          >
                            <div className="user-icon">
                              <UserGreyIcon />
                            </div>
                            <Form.Control
                              type="email"
                              placeholder="Username/Email"
                              className="email"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="Form.ControlInput2"
                          >
                            <div className="key-icon">
                              <KeyGreyIcon />
                            </div>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              className="password"
                            />
                            <div className="eye-icon">
                              <EyeGreyIcon />
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="Form.ControlInput3"
                          >
                            <div className="key-icon">
                              <KeyGreyIcon />
                            </div>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              className="password"
                            />
                            <div className="eye-icon">
                              <EyeGreyIcon />
                            </div>
                          </Form.Group>
                        </Form>
                        <div className="d-flex align-items-center justify-content-center">
                          <Button className="btn-theme w-100">Sign Up</Button>
                        </div>
                        <h5>OR</h5>
                        <Link href="" className="btn w-100 btn-social">
                          <FacebookBlueIcon /> Continue with Facebook
                        </Link>
                        <Link href="" className="btn w-100 btn-social mt-2">
                          <GooglePlusIcon />
                          Continue with Google
                        </Link>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
      {loader && <Loader />}
    </>
  );
};

export default RenterHeader;
