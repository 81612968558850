/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import RenterProfileTabs from "../../Commons/ProfileTabs";
import {
  addRating,
  getProductDetailsAfterCheckout,
  ratingDetails,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";

const WriteReview = () => {
  const location = useLocation();
  const role = useRole();
  const id = new URLSearchParams(location?.search).get("id");
  const pid = new URLSearchParams(location?.search).get("pid");
  const [product, setProduct] = useState("");
  const navigate = useNavigate();
  const [sliderImg, setSliderImg] = useState([]);

  useEffect(() => {
    ProductDetails();
  }, []);

  const ProductDetails = () => {
    getProductDetailsAfterCheckout(id).then((resp) => {
      if (resp?.data?.status === 200) {
        const tempArr = [];
        if (resp?.data?.data) {
          resp?.data?.data?.product_image?.map((data) => {
            if (data?.is_cover_photo) {
              tempArr.unshift({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            } else {
              tempArr.push({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            }
          });
        }
        setSliderImg(tempArr);
        setProduct(resp?.data?.data);
        if (role == RENTER) {
          RatingDetails(id);
        } else {
          RatingDetails(resp?.data?.data?.booked_by?.renter_id);
        }
      }
    });
  };

  const validationSchema = Yup.object({
    rating: Yup.string().required("Rate your overall experience"),
    review: Yup.string().required("This field is required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    handleBlur,
    setValues,
    touched,
  } = useFormik({
    initialValues: {
      product_id: "",
      user_id: "",
      rating: "",
      item_quality: "",
      communication: "",
      transport: "",
      value: "",
      item_maintained: "",
      punctuality: "",
      review: "",
      message: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let renterbody = {
        user_id: product?.lender_id,
        product_id: pid,
        rating: values?.rating,
        item_quality: values?.item_quality,
        communication: values?.communication,
        transport: values?.transport,
        review: values?.review,
        value: values?.value,
        message: values?.message,
      };

      let lenderbody = {
        user_id: product?.booked_by?.renter_id,
        product_id: pid,
        rating: values?.rating,
        item_maintained: values?.item_maintained,
        communication: values?.communication,
        punctuality: values?.punctuality,
        review: values?.review,
        message: values?.message,
      };

      let formData = new FormData();
      if (role == RENTER) {
        Object.keys(renterbody).forEach((data) => {
          if (renterbody[data]) {
            formData.append(data, renterbody[data]);
          }
        });
      } else {
        Object.keys(lenderbody).forEach((data) => {
          if (lenderbody[data]) {
            formData.append(data, lenderbody[data]);
          }
        });
      }
      addRating(formData).then((resp) => {
        setSubmitting(false);

        if (resp?.status === 200) {
          if (role == RENTER) {
            navigate("/renter/my-rental");
          } else {
            navigate("/lender/reservation");
          }
        } else {
          showNotification("danger", resp?.data?.message);
          if (role == RENTER) {
            navigate("/renter/my-rental");
          } else {
            navigate("/lender/reservation");
          }
        }
      });
    },
  });

  const RatingDetails = (id) => {
    ratingDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setValues({
          ...values,
          rating: resp?.data?.data?.rating,
          item_quality: resp?.data?.data?.item_quality,
          communication: resp?.data?.data?.communication,
          transport: resp?.data?.data?.transport,
          review: resp?.data?.data?.review,
          value: resp?.data?.data?.value,
          item_maintained: resp?.data?.data?.item_maintained,
          punctuality: resp?.data?.data?.punctuality,
        });
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="d-none d-lg-block d-md-block">
        <div className="mainbox_outer">
          {
            <RenterProfileTabs
              active={role == RENTER ? "my-rental" : "reservation"}
            />
          }
          <div className="category-detailview pb-3 pt-5">
            <Container>
              <Row>
                <Col lg={5} md={5} sm={12} className="position-relative">
                  <div className="d-flex d-lg-none mb-4 align-items-center">
                    <span className="me-3" onClick={() => navigate(-1)}>
                      <BackIcon />
                    </span>
                    <h4>{product?.title}</h4>
                  </div>
                  {role == RENTER ? (
                    <img
                      className="img-rounded profilelargeimg"
                      src={
                        product?.lender_profile_pic?.profile_pic
                          ? imageBase + product?.lender_profile_pic?.profile_pic
                          : require("../../assets/images/DummyImg.png")
                      }
                    />
                  ) : (
                    <img
                      className="img-rounded profilelargeimg"
                      src={
                        product?.renter_profile_pic?.profile_pic
                          ? imageBase + product?.renter_profile_pic?.profile_pic
                          : require("../../assets/images/DummyImg.png")
                      }
                    />
                  )}

                  {role == RENTER && (
                    <Row>
                      {sliderImg?.slice(0, 4)?.map((images, index) => {
                        return (
                          <Col sm={4} key={index}>
                            <div className="thumbnailimg">
                              <img
                                src={
                                  images?.original
                                    ? images?.original
                                    : require("../../assets/images/product1.jpg")
                                }
                                alt="img"
                                className="w-100"
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  )}

                  <div>
                    <p className="mb-0 font700 mt-4 font24">
                      Rate and review{" "}
                      {role === RENTER
                        ? product?.created_by_name
                        : product?.booked_by?.renter_name}
                    </p>
                    <h4 className="font24 font500">
                      {product?.title}
                      <br />
                      {moment(product?.booked_from).format("ll")} {"-"}{" "}
                      {moment(product?.booked_to).format("ll")}
                    </h4>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={7}
                  className="offset-lg-1 offset-md-0 offset-sm-0"
                  sm={12}
                >
                  <div className="ratingdetail-view">
                    {role == RENTER ? (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            Item Quality{" "}
                            {/* <span className="font400">
                              (What was the overall quality of the item?)
                            </span> */}
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="item_quality"
                              onChange={(e) => {
                                setFieldValue("item_quality", e);
                              }}
                              value={values?.item_quality}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            {/* Communication{" "}
                            <span className="font400">
                              (How well did {product?.created_by_name}{" "}
                              Communicate?)
                            </span> */}
                            Lender Communication
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              half={false}
                              count={5}
                              name="communication"
                              onChange={(e) => {
                                setFieldValue("communication", e);
                              }}
                              value={values?.communication}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            {/* Transport{" "}
                            <span className="font400">
                              (How was the delivery and return process?)
                            </span> */}
                            Delivery and Returns
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="transport"
                              onChange={(e) => {
                                setFieldValue("transport", e);
                              }}
                              value={values?.transport}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            {/* Values{" "}
                            <span className="font400">
                              (Was the price paid fair for the item rented?)
                            </span> */}
                            Value
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              onChange={(e) => {
                                setFieldValue("value", e);
                              }}
                              value={values?.value}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>

                        <hr className="hrgrey" />
                      </>
                    ) : (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            Item Maintained{" "}
                            <span className="font400">
                              (How well did{" "}
                              {role === RENTER
                                ? product?.created_by_name
                                : product?.booked_by?.renter_name}{" "}
                              take care of your item?)
                            </span>
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="item_maintained"
                              onChange={(e) => {
                                setFieldValue("item_maintained", e);
                              }}
                              value={values?.item_maintained}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            Communication{" "}
                            <span className="font400">
                              (How well did{" "}
                              {role === RENTER
                                ? product?.created_by_name
                                : product?.booked_by?.renter_name}
                              Communicate?)
                            </span>
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="communication"
                              onChange={(e) => {
                                setFieldValue("communication", e);
                              }}
                              value={values?.communication}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font700">
                            Punctuality{" "}
                            <span className="font400">
                              (Was the item delivered/returned on time?)
                            </span>
                          </Form.Label>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="punctuality"
                              onChange={(e) => {
                                setFieldValue("punctuality", e);
                              }}
                              value={values?.punctuality}
                              color2={"#16ff96"}
                            />
                          </div>
                        </Form.Group>
                        <hr className="hrgrey" />
                      </>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label className="font700">
                        Describe your experience renting to{" "}
                        {role === RENTER
                          ? product?.created_by_name
                          : product?.booked_by?.renter_name}{" "}
                        <span className="error">*</span>
                      </Form.Label>
                      <textarea
                        type="textarea"
                        name="review"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.review}
                        maxLength={300}
                        className="form-control min-height140"
                      ></textarea>
                       <span className="text-start required">
                          {errors.review && touched.review && errors.review}{" "}
                        </span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700">
                        Private message to{" "}
                        {role === RENTER
                          ? product?.created_by_name
                          : product?.booked_by?.renter_name}
                      </Form.Label>
                      <textarea
                        type="textarea"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.message}
                        maxLength={300}
                        className="form-control min-height140"
                      ></textarea>
                    </Form.Group>

                    {role === RENTER ? (
                      <Form.Group className="mb-3">
                        <Form.Label className="font700">
                          Rate your overall experience with{" "}
                          {product?.created_by_name &&
                            `${product?.created_by_name}'s`}{" "}
                          Rental <span className="error">*</span>
                        </Form.Label>
                        <div className="starslist">
                          <ReactStars
                            className="star_rating"
                            size={24}
                            count={5}
                            name="rating"
                            half={false}
                            onChange={(e) => {
                              setFieldValue("rating", e);
                            }}
                            value={values?.rating}
                            color2={"#16ff96"}
                          />
                        </div>
                        <span className="text-start required">
                          {errors.rating && touched.rating && errors.rating}{" "}
                        </span>
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-3">
                        <Form.Label className="font700">
                          {" "}
                          Rate your overall experience renting to{" "}
                          {role === RENTER
                            ? product?.created_by_name
                            : product?.booked_by?.renter_name}
                          <span className="error">*</span>
                        </Form.Label>
                        <div className="starslist">
                          <ReactStars
                            className="star_rating"
                            size={24}
                            count={5}
                            half={false}
                            name="rating"
                            onChange={(e) => {
                              setFieldValue("rating", e);
                            }}
                            value={values?.rating}
                            color2={"#16ff96"}
                          />
                        </div>
                        <span className="text-start required">
                          {" "}
                          {errors.rating &&
                            touched.rating &&
                            errors.rating}{" "}
                        </span>
                      </Form.Group>
                    )}

                    <Button
                      className="btn w-100 btn-theme mt-2"
                      onClick={handleSubmit}
                    >
                      Submit feedback
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div className="d-block d-lg-none d-md-none d-xl-none ">
        <div className="category-detailview pb-3 pt-5">
          <Container>
            <Row>
              <Col lg={12} className="position-relative">
                {/* <h4>Write a review for {product?.title}</h4> */}
                {role == RENTER ? (
                  <>
                    <h1>
                      Write a review for {product?.created_by_name}{" "}
                      {product?.title}
                    </h1>
                    <img
                      className="img-rounded profilelargeimg smallprofile"
                      src={
                        product?.lender_profile_pic?.profile_pic
                          ? imageBase + product?.lender_profile_pic?.profile_pic
                          : require("../../assets/images/DummyImg.png")
                      }
                    />
                  </>
                ) : (
                  <>
                    <h1>
                      Write a review for your experience lending your items to{" "}
                      {product?.booked_by?.renter_name}
                    </h1>

                    <img
                      className="img-rounded profilelargeimg smallprofile"
                      src={
                        product?.renter_profile_pic?.profile_pic
                          ? imageBase + product?.renter_profile_pic?.profile_pic
                          : require("../../assets/images/DummyImg.png")
                      }
                    />
                  </>
                )}
              </Col>
            </Row>
            {role == RENTER && (
              <Row>
                {sliderImg?.slice(0, 4)?.map((images, index) => {
                  return (
                    <Col sm={4} key={index}>
                      <div className="thumbnailimg">
                        <img
                          src={
                            images?.original
                              ? images?.original
                              : require("../../assets/images/product1.jpg")
                          }
                          alt="img"
                          className="w-100"
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
            <br />
            <p>
              Tell us how things went with{" "}
              {role === RENTER
                ? product?.created_by_name
                : product?.booked_by?.renter_name}{" "}
              item. Your feedback encourages other renters to be considerate and
              helps other users what to expect with this rental. We'll wait to
              publish your review for 14 days or until{" "}
              {role === RENTER
                ? product?.created_by_name
                : product?.booked_by?.renter_name}{" "}
              writes a review for you.
            </p>
            <br />
            <div className="d-flex align-items-center justify-content-center">
              <Link
                className="btn btn-theme height42 width200"
                to={
                  role == RENTER
                    ? `/renter/addratingmobile?id=${id}&pid=${pid}`
                    : `/lender/addratingmobile?id=${id}&pid=${pid}`
                }
              >
                Get started
              </Link>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WriteReview;
