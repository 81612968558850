/**
 * 
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*
*/

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Paginate from "../../Pagination/Pagination";
import { chatUser } from "../../Redux/ChatUserNameSlice";
import { chatListApi } from "../../Services/APIServices";
import { MsgIcon } from "../../SvgIcons/allIcons";
import { truncate, splitFullName } from "../../globals/HelperCommon";
import { PERPAGE_THIRTY, RENTER } from "../../globals/constants";
import { imageBase } from "../../globals/Config";
import Loader from "../../Commons/Loader/Loader";
import useRole from "../../hooks/useRole";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";

export const MessageLeftBar = ({
  currentOrderId,
  setOrderId,
  setReceiverId,
  setProductId,
  scrollToBottom,
}) => {
  const dispatch = useDispatch();
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [meta, setMeta] = useState("");
  const [page, setPage] = useState("1");
  const [perPage, setPerPage] = useState(PERPAGE_THIRTY);
  const [activeIndex, setActiveIndex] = useState(null);
  const role = useRole();

  useEffect(() => {
    getChatSaved(search, page, perPage);
  }, []);

  const getChatSaved = (search, page, perPage = PERPAGE_THIRTY) => {
    chatListApi(search, page, perPage).then((resp) => {
      setLoading(false);
      if (resp?.data?.status === 200) {
        setChatList(resp?.data?.data);
      }
    });
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    getChatSaved(search, e.selected + 1, perPage);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    getChatSaved(e.target.value, page, perPage);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    getChatSaved(search, page, e.target.value);
  };

  return (
    <ul className="users-list">
      {chatList && chatList?.length > 0
        ? chatList?.map((data, index) => {
          return (
            <li
              className={`d-flex align-items-start cursor ${activeIndex === index || data?.product_detail?.order_id === currentOrderId ? "active" : ""}`}
              key={index}
              onClick={() => {
                setActiveIndex(index); // Set the active index to the clicked item
                setOrderId(data?.product_detail?.order_id);
                setReceiverId(data?.receiver?.id);
                setProductId(data?.product_detail?.product_id);
                dispatch(chatUser(data?.receiver?.full_name));
                setTimeout(() => {
                  scrollToBottom();
                }, 1000);
              }}
            >
              {data?.receiver?.profile_pic ? (
                <img
                  width="80"
                  height="80"
                  src={
                    data?.receiver?.profile_pic
                      ? imageBase + data?.receiver?.profile_pic
                      : require("../../assets/images/DummyImg.png")
                  }
                  alt="Profile Image"
                />
              ) : (
                <DefaultProfileImg userDetails={splitFullName(data?.receiver?.full_name)} />
              )}

              <div className="ms-3">
                <h5 className="font14 font700 colorgreen mb-1">
                  {role === RENTER ? "Your Lender" : "Your Renter"}
                </h5>
                <p className="msg font14 font700 colordark mb-2">
                  <span className="font400">
                    {data?.last_message?.message_file ? (
                      <>
                        <img
                          src={
                            data?.last_message?.message_file
                              ? imageBase + data?.last_message?.message_file
                              : require("../../assets/images/static_image.jpg")
                          }
                          className="last-msg-img me-2"
                        />
                        Media file
                      </>
                    ) : (
                      truncate(data?.last_message?.message, 35)
                    )}
                  </span>
                  <br />
                  {truncate(
                    `${moment(data?.product_detail?.from).format(
                      "DD MMM"
                    )} - ${moment(data?.product_detail?.to).format(
                      "DD MMM"
                    )} - ${data?.product_detail?.product_name}`,
                    35
                  )}
                </p>
                <h4 className="user-name font18 font700 colordark mb-0">
                  {data?.receiver?.full_name}
                </h4>
              </div>
            </li>
          );
        })
        : !loading && (
          <div className="justify-content-center d-flex flex-column align-items-center h60vh ">
            <MsgIcon />
            <p className="font16 font700 mb-0 mt-2">
              You do not have any conversations yet
            </p>
          </div>
        )}

      <Paginate
        page={meta?.page_count}
        handlePageClick={(e) => handlePageChange(e)}
        cp={page}
      />

      {loading && <Loader />}
    </ul>
  );
};

export default MessageLeftBar;
