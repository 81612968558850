import { useEffect } from "react";

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const splitFullName = (fullName = "") => {
  // Ensure fullName is a string and trim extra spaces
  const nameParts = fullName.trim().split(" ").filter((part) => part);

  let first_name = "";
  let middle_name = "";
  let last_name = "";

  // Assign values based on the number of parts
  if (nameParts.length === 1) {
    first_name = nameParts[0];
  } else if (nameParts.length === 2) {
    first_name = nameParts[0];
    last_name = nameParts[1];
  } else if (nameParts.length >= 3) {
    first_name = nameParts[0];
    last_name = nameParts[nameParts.length - 1];
    middle_name = nameParts.slice(1, nameParts.length - 1).join(" ");
  }

  return {
    first_name,
    middle_name,
    last_name,
  };
};
