export const ERRORMSG = "Something went wrong.";
export const LOGOUT = "Are you sure you want to log out?";
export const SIGNOUT = "logout Successfully";
export const FAIL = "Email or Password are not valid";
export const StatusTrue = 200;
export const ONE = 1;
export const PERPAGE = 20;
export const perPageData = 12;

export const ADMIN = 1;
export const RENTER = 2;
export const LENDER = 3;

export const ACTIVE_STATE = 1;
export const INACTIVE_STATE = 0;
export const DRAFT_STATE = 2;
export const ALL_STATE = 3;

export const PERPAGE_TEN = 10;
export const PERPAGE_TWENTY = 20;
export const PERPAGE_THIRTY = 30;

export const RENTER_CURRENT_RENTING = 1;
export const RENTER_PAST_RENTALS = 2;
export const RENTER_FUTURE_RENTALS = 3;

export const HOME_PAGE_CATEGORY_LIMIT = 4;

/*********************Days Const **************/
export const WEEKLY = 7;
export const BIWEEKLY = 14;
export const MONTHLY = 28;
export const THIRTY = 30;

/*********************Sort Const*************/
export const HIGH_TO_LOW = 1;
export const LOW_TO_HIGH = 0;

/********************Report Constants***********/
export const SCAMMING = 1;
export const OFFENSIVE = 2;
export const OTHER = 3;

/*************My Rental Constant************/
export const CURRENT = 1;
export const PAST = 2;
export const UPCOMING = 3;

/***************Static Pages Constants************/
export const TERMS_AND_CONDITION = 1;
export const PRIVACY_POLICY = 2;
export const ABOUT_US = 3;
export const HOW_IT_WORKS = 4;
export const SUSTAINABILITY = 5;
export const COOKIES = 6;

/***************Card Type**********************/
export const VISA = "Visa";
export const MASTERCARD = "MasterCard";

/****************Form Steps*******************/
export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const STEP_THREE = 3;
export const STEP_FOUR = 4;
export const STEP_FIVE = 5;
export const STEP_SIX = 6;
export const STEP_SEVEN = 7;
export const STEP_EIGHT = 8;

export const LIST_AVAILABILITY_AVAILABLE = "1"; // true
export const LIST_AVAILABILITY_BLOCKED = "0"; // false

/********* CURRENCY *******/
export const DEFAULT_CURRENCY = "₹";


export const GOOGLE = 1
export const FACEBOOK = 2

/********* Delivery Options *******/
// Home / Store means shiprocket
// Person Means Self Delivery
export const HOME = 1;
export const STORE = 2;
export const SELF = 3;

/********* Self Delivery Request Status *******/
export const SELF_DELIVERY_REQUEST_PENDING = 1;
export const SELF_DELIVERY_REQUEST_ACCEPTED = 2;
export const SELF_DELIVERY_REQUEST_REJECTED = 3;
