/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useState } from "react";
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import {
  CalendarIcon,
  CrossIcon,
  DeletecircleIcon,
  EditIcon,
  EditcircleIcon,
  SearchGrayIcon,
  VisaIcon,
} from "../SvgIcons/allIcons";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Footer from "../Commons/Footer";
import useRole from "../hooks/useRole";
import { LENDER, RENTER } from "../globals/constants";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Header from "../Commons/Headers/Header";
const Contact = () => {
  const role = useRole();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setshowDelete] = useState(false);
  const handleCloseTwo = () => setshowDelete(false);
  const handleShowTwo = () => setshowDelete(true);

  const [showDismissDelete, setshowDismissDelete] = useState(false);
  const handleCloseThree = () => setshowDismissDelete(false);
  const handleShowThree = () => setshowDismissDelete(true);

  const [showPaymentDelete, setshowPaymentDelete] = useState(false);
  const handleClosePayment = () => setshowPaymentDelete(false);
  const handleShowPayment = () => setshowPaymentDelete(true);

  const [showPaymentDelete1, setshowPaymentDelete1] = useState(false);
  const handleClosePayment1 = () => setshowPaymentDelete1(false);
  const handleShowPayment1 = () => setshowPaymentDelete1(true);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="top-head bottom-border pb-3">
          <Container>
            <div className="row  align-items-center">
              <Col lg={10} md={9} sm={12}>
                <ul className="d-flex flex-wrap align-items-center subtop-menu">
                  <li>My Listings</li>
                  <li>Reservations</li>
                  <li>My Profile</li>
                  <li className="active">
                    <a href="/contact"> Settings</a>
                  </li>
                </ul>
              </Col>
              <Col lg={2} md={3} sm={12}>
                <Form.Select aria-label="Default select example">
                  <option>Lender</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
            </div>
          </Container>
        </div>
        <div className="accountmain">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Container fluid>
              <Row>
                <Col lg={4} md={4} sm={12} className="bggrey pt-5 pb-5">
                  <Row>
                    <Col lg={4} md={3} sm={12}></Col>
                    <Col lg={8} md={9} sm={12}>
                      <Nav variant="pills" className="flex-column sidebarnav">
                        <Nav.Item>
                          <Nav.Link eventKey="first" className="pt-0">
                            Personal Info
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Contact Info</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Address</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Payments</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fifth">Membership</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </Col>
                <Col lg={8} md={8} sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first" className="pt-5 pb-5">
                      <p className="head16">Personal Information</p>
                      <Row>
                        <Col lg={4} md={6}>
                          <Form>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "105px",
                                width: "105px",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                ref={imageUploader}
                                style={{
                                  display: "none",
                                }}
                              />
                              <div
                                style={{
                                  height: "75px",
                                  width: "75px",
                                  border: "1px solid #eee",
                                  borderRadius: "50%",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    background: "#16FF96",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    right: "0",
                                  }}
                                  onClick={() => imageUploader.current.click()}
                                >
                                  <EditIcon />
                                </div>
                                <img
                                  ref={uploadedImage}
                                  src={require("../assets/images/user.png")}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>
                            </div>

                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Username
                              </Form.Label>
                              <Form.Control type="text" value="csteward1990" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                First Name
                              </Form.Label>
                              <Form.Control type="text" value="Calvin" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Last Name
                              </Form.Label>
                              <Form.Control type="text" value="Steward" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Middle Name
                              </Form.Label>
                              <Form.Control type="text" value="csteward1990" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Date of Birth
                              </Form.Label>
                              <div className="datepicker position-relative">
                                <Form.Control type="date" value="11/02/1990" />
                                <span className="position-absolute">
                                  <CalendarIcon />
                                </span>
                              </div>
                            </Form.Group>
                            <Button className="btn btn-theme font700">
                              Save
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="pt-5 pb-5">
                      <p className="head16">Contact Information</p>
                      <Row>
                        <Col lg={4} md={6}>
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Email
                              </Form.Label>
                              <Form.Control type="email" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Phone Number
                              </Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                LinkedIn
                              </Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Facebook
                              </Form.Label>
                              <Form.Control type="text" />
                            </Form.Group>
                            <Button className="btn btn-theme font700">
                              Save
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third" className="pt-5 pb-5">
                      <Row>
                        <Col lg={10} md={10}>
                          <p className="head16">Address</p>
                          <Row>
                            <Col lg={4} md={6}>
                              <CardGroup className="addresscard">
                                <div className="d-flex justify-content-between w-100">
                                  <p className="mb-0">
                                    4517 Washington Ave.
                                    <br />
                                    Manchester,
                                    <br />
                                    Kentucky
                                    <br />
                                    39495
                                  </p>
                                  <div className="d-flex">
                                    <Link to="">
                                      <EditcircleIcon />
                                    </Link>
                                    &nbsp;&nbsp;
                                    <Link onClick={handleShowTwo}>
                                      <DeletecircleIcon />
                                    </Link>
                                  </div>
                                </div>
                              </CardGroup>
                            </Col>
                            <Col lg={4} md={6}>
                              <CardGroup className="addresscard">
                                <div className="d-flex justify-content-between w-100">
                                  <p className="mb-0">
                                    4517 Washington Ave.
                                    <br />
                                    Manchester,
                                    <br />
                                    Kentucky
                                    <br />
                                    39495
                                  </p>
                                  <div className="d-flex">
                                    <Link to="">
                                      <EditcircleIcon />
                                    </Link>
                                    &nbsp;&nbsp;
                                    <Link onClick={handleShowTwo}>
                                      <DeletecircleIcon />
                                    </Link>
                                  </div>
                                </div>
                              </CardGroup>
                            </Col>

                            <Col lg={4} md={6}>
                              <Link onClick={handleShow} className="h-100">
                                <CardGroup className="addresscard addadress d-flex align-items-center justify-content-center">
                                  <p className="mb-0 font700">
                                    Add new address
                                  </p>
                                </CardGroup>
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth" className="pt-5 pb-5">
                      <p className="head16">Payments</p>
                      <p className="font12 font700">Cards</p>
                      <Row>
                        <Col lg={4} md={6}>
                          <CardGroup className="addresscard payment-gredientcard heightauto">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <p className="mt-lg-5 text-white">
                                  <VisaIcon />
                                </p>
                                <p className="mb-2 text-white">
                                  Calvin Steward
                                </p>
                                <p className="mb-2 font400 text-white">
                                  XXXX-XXXX-XXXX-1325
                                </p>
                                <p className="mb-0 font400 text-white">
                                  Exp. 02/24
                                </p>
                              </div>
                              <div className="d-flex">
                                <Link onClick={handleShowTwo}>
                                  <DeletecircleIcon />
                                </Link>
                              </div>
                            </div>
                          </CardGroup>
                        </Col>
                        <Col lg={4} md={6}>
                          <CardGroup className="addresscard payment-gredientcard heightauto">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <p className="mt-lg-5 text-white">
                                  <VisaIcon />
                                </p>
                                <p className="mb-2 text-white">
                                  Calvin Steward
                                </p>
                                <p className="mb-2 font400 text-white">
                                  XXXX-XXXX-XXXX-1325
                                </p>
                                <p className="mb-0 font400 text-white">
                                  Exp. 02/24
                                </p>
                              </div>
                              <div className="d-flex">
                                <Link onClick={handleShowTwo}>
                                  <DeletecircleIcon />
                                </Link>
                              </div>
                            </div>
                          </CardGroup>
                        </Col>

                        <Col lg={4} md={6}>
                          <Link onClick={handleShow}>
                            <CardGroup className="addresscard add-payment addadress d-flex align-items-center justify-content-center">
                              <p className="mb-0 font700">Add new address</p>
                            </CardGroup>
                          </Link>
                        </Col>
                      </Row>
                      <br />
                      <p className="font12 font700">Bank Accounts</p>
                      <Row>
                        <Col lg={4} md={6}>
                          <CardGroup className="addresscard">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <p className="mb-2">Calvin Steward</p>
                                <p className="mb-0 font400">
                                  Ending with 10604
                                </p>
                                <p className="mb-0 font400">BIC. GB24BKEN</p>
                              </div>
                              <div className="d-flex">
                                <Link onClick={handleShowPayment}>
                                  <DeletecircleIcon />
                                </Link>
                              </div>
                            </div>
                          </CardGroup>
                        </Col>
                        <Col lg={4} md={6}>
                          <CardGroup className="addresscard">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <p className="mb-2">Calvin Steward</p>
                                <p className="mb-0 font400">
                                  Ending with 10604
                                </p>
                                <p className="mb-0 font400">BIC. GB24BKEN</p>
                              </div>
                              <div className="d-flex">
                                <Link onClick={handleShowPayment}>
                                  <DeletecircleIcon />
                                </Link>
                              </div>
                            </div>
                          </CardGroup>
                        </Col>

                        <Col lg={4} md={6}>
                          <Link onClick={handleShowPayment} className="h-100">
                            <CardGroup className="addresscard addadress d-flex align-items-center justify-content-center">
                              <p className="mb-0 font700">Add new bank</p>
                            </CardGroup>
                          </Link>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Container>
          </Tab.Container>
        </div>
      </div>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        className="addresmodal text-start"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Add new address</h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Address 1
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Address 2
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Region/Area
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    City
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Zip Code
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Country
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Phone Number
              </Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Button className="btn btn-theme font700 mb-0">Save</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={handleCloseTwo}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete Address</h4>
            <a onClick={handleCloseTwo}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Do you want remove following address</p>

          <Row>
            <Col lg={10}>
              <CardGroup className="addresscard shadow-none mb-3">
                <div className="d-flex justify-content-between w-100">
                  <p className="mb-0">
                    4517 Washington Ave.
                    <br />
                    Manchester,
                    <br />
                    Kentucky
                    <br />
                    39495
                  </p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button className="btn btn-danger mb-0" onClick={handleShowThree}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDismissDelete}
        onHide={handleCloseThree}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Address Deleted!</h4>
            <a onClick={handleCloseThree}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Following address has been deleted!</p>

          <Row>
            <Col lg={10}>
              <CardGroup className="addresscard shadow-none mb-3">
                <div className="d-flex justify-content-between w-100 ">
                  <p className="mb-0">
                    4517 Washington Ave.
                    <br />
                    Manchester,
                    <br />
                    Kentucky
                    <br />
                    39495
                  </p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button className="btn btn-theme mb-0">Dismiss</Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPaymentDelete}
        onHide={handleClosePayment}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete bank account</h4>
            <a onClick={handleClosePayment}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Do you want remove following Card?</p>

          <Row>
            <Col lg={10}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-0 font400">XXXX-XXXX-XXXX-1325</p>
                  <p className="mb-0 font700">Calvin Steward</p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button className="btn btn-danger mb-0" onClick={handleShowPayment1}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPaymentDelete1}
        onHide={handleClosePayment1}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="border0"
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Bank account deleted!</h4>
            <a onClick={handleClosePayment1}>
              <CrossIcon />
            </a>
          </div>
          <p className="font700">Following card has been deleted!</p>

          <Row>
            <Col lg={10}>
              <CardGroup className="addresscard mb-4 ">
                <div className="d-flex flex-column w-100">
                  <p className="mb-0 font400">XXXX-XXXX-XXXX-1325</p>
                  <p className="mb-0 font700">Calvin Steward</p>
                </div>
              </CardGroup>
            </Col>
          </Row>
          <Button className="btn btn-theme mb-0" onClick={handleShowPayment1}>
            Dismiss
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Contact;
