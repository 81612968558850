/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Nodata from "../../Commons/CommonModals/Nodata";
import Footer from "../../Commons/Footer";
import ProfileTabs from "../../Commons/ProfileTabs";
import Paginate from "../../Pagination/Pagination";
import Reservationmobile from "./Reservationmobile";
import moment from "moment";
import { Link } from "react-router-dom";
import Dropdown from "../../Commons/Dropdown";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import { reservedProduct, actionDeliveryRequest } from "../../Services/APIServices";
import { BtnRightIcon } from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import {
  LENDER,
  PERPAGE_TEN,
  PERPAGE_THIRTY,
  PERPAGE_TWENTY,
  RENTER,
  RENTER_CURRENT_RENTING,
  RENTER_FUTURE_RENTALS,
  RENTER_PAST_RENTALS,
  SELF,
  HOME,
  STORE,
  SELF_DELIVERY_REQUEST_PENDING,
  SELF_DELIVERY_REQUEST_ACCEPTED,
  SELF_DELIVERY_REQUEST_REJECTED
} from "../../globals/constants";
import useRole from "../../hooks/useRole";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";
import MessageModal from "./../CommonModals/MessageModal";
import showNotification from "../../Services/NotificationService";
import { splitFullName } from "../../globals/HelperCommon";

const Reservation = () => {
  const role = useRole();
  const [product, setProduct] = useState([]);
  const [meta, setMeta] = useState("");
  const [page, setPage] = useState("1");
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const order_id = new URLSearchParams(location?.search).get("order_id") ?? "";
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [pendingOrderId, setPendingOrderId] = useState(null);

  const openMessageModal = (action, orderId) => {
    setPendingAction(action);
    setPendingOrderId(orderId);
    setShowMessageModal(true);
  };

  const handleMessageSubmit = (message) => {
    setShowMessageModal(false);
    
    // Execute the pending action with the captured message
    if (pendingAction === SELF_DELIVERY_REQUEST_ACCEPTED) {
      handleAcceptDeliveryRequest(pendingOrderId, message);
    } else if (pendingAction === SELF_DELIVERY_REQUEST_REJECTED) {
      handleRejectDeliveryRequest(pendingOrderId, message);
    }
  };

  const handleAcceptDeliveryRequest = (order_id,message) => {
    setLoader(true);
    const body = {
      order_id: order_id,
      action: SELF_DELIVERY_REQUEST_ACCEPTED,
      message: message
    }
    actionDeliveryRequest(body)
      .then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.message || 'Request approved successfully');
          getreserveProductApi(page, perPage, search?.value);
        } else {
          showNotification('error', resp?.message || 'Failed to Update request');
        }
      })
      .catch((error) => {
        showNotification('error', 'Something went wrong');
      })
      .finally(() => {
        setLoader(false);        
        setPendingAction(null);
        setPendingOrderId(null);
      });
  }

  const handleRejectDeliveryRequest = (order_id, message) => {
    setLoader(true);
    const body = {
      order_id: order_id,
      action: SELF_DELIVERY_REQUEST_REJECTED,
      message: message
    }
    actionDeliveryRequest(body)
      .then((resp) => {
        if (resp?.status === 200) {
          showNotification('success', resp?.message || 'Request rejected successfully');
          getreserveProductApi(page, perPage, search?.value);
        } else {
          showNotification('error', resp?.message || 'Failed to update request');
        }
      })
      .catch((error) => {
        showNotification('error', 'Something went wrong');
      })
      .finally(() => {
        setLoader(false);        
        setPendingAction(null);
        setPendingOrderId(null);
      });
  }

  useEffect(() => {
    getreserveProductApi(page, perPage, search?.value);
  }, []);

  const getreserveProductApi = (page, perPage = PERPAGE_TEN, search = "") => {
    reservedProduct(page, perPage, search)
      .then((resp) => {
        setLoader(true);
        if (resp?.status === 200) {
          setProduct(resp?.data?.data);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoader(false));
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    getreserveProductApi(e.selected + 1, perPage, search?.value);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    getreserveProductApi(page, e.target.value, search?.value);
  };

  const handleSearch = () => {
    getreserveProductApi(page, perPage, search?.value);
  };

  const handleViewReservtion = (data) => {
    navigate(
      `/lender/reservation-detail?id=${data?.id}&pid=${data?.product_id}`
    );
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="d-none d-md-block">
        <div className="mainbox_outer">
          <ProfileTabs active="reservation" />
          <div className="top-breadcrumb top-area">
            <Container>
              <Row className="align-items-center">
                <Col lg={2} md={2} sm={12}>
                  <Form.Label className="font700">View</Form.Label>
                  <Dropdown
                    name="reservation"
                    value={search}
                    clearable={true}
                    options={[
                      {
                        label: "Currently Renting",
                        value: RENTER_CURRENT_RENTING,
                      },
                      { label: "Past Rentals", value: RENTER_PAST_RENTALS },
                      { label: "Future Rentals", value: RENTER_FUTURE_RENTALS },
                    ]}
                    placeholder={"Select"}
                    onChange={setSearch}
                  />
                </Col>
                <Col lg={2} md={3} sm={12}>
                  <Button className="btn btn-theme mb-0" onClick={handleSearch}>
                    Apply Filters
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="accountmain">
            <div className="category-boxes heightauto mt-4 mb-2">
              <Container>
                <Row>
                  {product && product?.length > 0 ? (
                    product?.map((data, index) => {
                      return (
                        <Col lg={3} md={4} sm={12} key={index} className="mb-2">
                          <Card
                            className={
                              order_id == data?.id ? "active mb-4" : "mb-4"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <Card.Img
                              variant="top"
                              onClick={() => handleViewReservtion(data)}
                              src={
                                data?.product_image?.images
                                  ? imageBase + data?.product_image?.images
                                  : require("../../assets/images/static_image.jpg")
                              }
                            />
                            <Card.Body>
                              <div
                                className="d-flex align-items-center gap-3 img-box mb-3"
                                onClick={() =>
                                  navigate(
                                    `/lender/user-profile?user_id=${data?.created_by?.id}`
                                  )
                                }
                              >
                                {data?.created_by_image ? (
                                  <img
                                    src={
                                      data?.created_by_image
                                        ? imageBase + data?.created_by_image
                                        : require("../../assets/images/DummyImg.png")
                                    }
                                    className="avtar rounded-circle"
                                    alt="Img"
                                  />
                                ) : (
                                  <DefaultProfileImg userDetails={splitFullName(data?.created_by?.full_name)} />
                                )}
                                <h6 className="mb-0">
                                  {data?.created_by?.full_name}
                                </h6>
                              </div>
                              <div>
                                <p className="font12 font700 mb-1 d-flex justify-content-between align-items-center">
                                  <i>
                                    {moment(data?.booked_from).format("DD-MM-YYYY")}
                                    {" "}{"to"}{" "}
                                    {moment(data?.booked_to).format("DD-MM-YYYY")}
                                  </i>
                                  {data?.delivery_options == SELF ? (
                                    <>
                                      {data?.request_status == SELF_DELIVERY_REQUEST_PENDING ? (
                                        <span className="d-flex sd-btn-wrapper">
                                          <Button 
                                            onClick={() => openMessageModal(SELF_DELIVERY_REQUEST_ACCEPTED, data?.order_id)}
                                            className="btn-success me-2"
                                          >
                                            Accept
                                          </Button>
                                          <Button 
                                            onClick={() => openMessageModal(SELF_DELIVERY_REQUEST_REJECTED, data?.order_id)}
                                            className="btn-danger"
                                          >
                                            Reject
                                          </Button>
                                        </span>
                                      ) : null}
                                      {data?.request_status === SELF_DELIVERY_REQUEST_REJECTED && (
                                        <span className="badge badge-pending bg-danger font12 font700">Rejected on {moment(data?.responded_at).format("YYYY-MM-DD") || ''}</span>
                                      )}
                                    </>
                                  ) : null}
                                </p>

                                <h5 className="head16" onClick={() => handleViewReservtion(data)}>{data?.title}</h5>
                                <span className="colordark font700 font12 mb-0 btn-shop" onClick={() => handleViewReservtion(data)}>
                                  See More Details{" "}
                                  <span className="ms-2">
                                    <BtnRightIcon />{" "}
                                  </span>
                                </span>
                              </div>

                              <p className="mb-0">
                                {moment(data?.booked_to).format("YYYY-MM-DD") <
                                  moment().format("YYYY-MM-DD") && (
                                    <Link
                                      to={`/lender/write-review?id=${data?.id}&pid=${data?.product_id}`}
                                      className="colordark font700 font12 mb-0 btn-shop"
                                    >
                                      Write Review{" "}
                                      <span className="ms-2">
                                        <BtnRightIcon />{" "}
                                      </span>
                                    </Link>
                                  )}
                              </p>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                  ) : (
                    <>{!loader && <Nodata />}</>
                  )}
                </Row>
                <div className="d-flex align-items-center justify-content-between">
                  <Paginate
                    page={meta?.page_count}
                    handlePageClick={(e) => handlePageChange(e)}
                    cp={page}
                  />
                  <div className="d-flex align-items-center justify-content-end flex-wrap gap-2 mb-3">
                    <h6 className="font12 font400 colordark mb-0">Show</h6>
                    <div style={{ width: "70px" }}>
                      <Form.Select
                        aria-label="Default select"
                        className="font14 font400"
                        value={perPage}
                        onChange={handlePerPageChange}
                      >
                        <option value={PERPAGE_TEN}>10</option>
                        <option value={PERPAGE_TWENTY}>20</option>
                        <option value={PERPAGE_THIRTY}>30</option>
                      </Form.Select>
                    </div>
                    <h5 className="font12 font400 colordark mb-0">per page</h5>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        {loader && <Loader />}
      </div>

      <div className="d-block d-md-none">
        <Reservationmobile />
        {/* <MobileCategoryItem /> */}
      </div>

      <MessageModal
        show={showMessageModal}
        onHide={() => {
          setShowMessageModal(false);
          setPendingAction(null);
          setPendingOrderId(null);
        }}
        onSubmit={handleMessageSubmit}
        role={'lender'}
      />
    </>
  );
};

export default Reservation;
