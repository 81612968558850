import { useFormik } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { CrossIcon } from "../../SvgIcons/allIcons";

const MessageModal = ({ show, onHide, onSubmit, role="" }) => {
  const handleClose = () => {
    onHide();
    resetForm();
  };

  const validationSchema = Yup.object({
    message: Yup.string()
      .required("Please enter your message")
      .min(10, "Message must be at least 10 characters")
      .max(250, "Message cannot exceed 250 words")
      .trim(),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values.message);
      handleClose();
    },
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="addresmodal text-start"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          {role === 'lender' ? (
            <h4 className="text-start">Message to Renter</h4>
          ) : (
            <h4 className="text-start">Message to Lender</h4>
          ) }
          <a onClick={handleClose}>
            <CrossIcon />
          </a>
        </div>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="font700 font12 secondaycolor">
              Message <span className="error">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              name="message"
              placeholder={
                role === 'lender' ? "Write your message about pickup time and date to tell the Renter" : "Write your message about pickup time and date to tell the Lender"
              }
              onChange={handleChange}
              value={values?.message}
              onBlur={handleBlur}
              maxLength={250}
            />
            <div className="d-flex justify-content-between">
              <span className="text-start required">
                {touched.message && errors.message}
              </span>
              <small className="text-muted">
                {values?.message?.length || 0}/250
              </small>
            </div>
          </Form.Group>

          <Button
            type="button"
            className="btn btn-theme font700 mb-0 w-100"
            onClick={handleSubmit}
          >
            Attach Message
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;
