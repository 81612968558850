/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import moment from "moment";
import Nodata from "../../Commons/CommonModals/Nodata";
import Dropdown from "../../Commons/Dropdown";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import RenterProfileTabs from "../../Commons/ProfileTabs";
import { myRentelListing } from "../../Services/APIServices";
import {
  BtnRightIcon,
  CrossDarkIcon,
  ReviewCommentIcon,
  SearchGrayIcon,
} from "../../SvgIcons/allIcons";
import {
  CURRENT,
  LENDER,
  PAST,
  PERPAGE_TEN,
  PERPAGE_THIRTY,
  PERPAGE_TWENTY,
  RENTER,
  UPCOMING,
  SELF,
  SELF_DELIVERY_REQUEST_PENDING,
  SELF_DELIVERY_REQUEST_ACCEPTED,
  SELF_DELIVERY_REQUEST_REJECTED
} from "../../globals/constants";
import useRole from "../../hooks/useRole";
import Paginate from "../../Pagination/Pagination";
import { imageBase } from "../../globals/Config";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";
import { splitFullName } from "../../globals/HelperCommon";

const MyRentals = () => {
  const role = useRole();
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [meta, setMeta] = useState("");
  const [rentalType, setRentalType] = useState("");
  const [page, setPage] = useState("1");
  const [loading, setLoding] = useState(true);
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const navigate = useNavigate();

  useEffect(() => {
    productListing(search, rentalType?.value, page, perPage);
  }, []);

  const productListing = (search = "", rentalType, page, perPage) => {
    myRentelListing(search, rentalType, page, perPage)
      .then((resp) => {
        setLoding(false);
        if (resp?.data?.status === 200) {
          setList(resp?.data?.data);
          setMeta(resp?.data?.meta_data);
        }
      })
      .finally(() => setLoding(false));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    productListing(e.target.value, rentalType?.value, page, perPage);
  };

  const handleRentalTypeChange = (e) => {
    setRentalType(e);
    productListing(search, e?.value, page, perPage);
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    productListing(search, rentalType?.value, e.selected + 1, perPage);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    productListing(search, rentalType?.value, page, e.target.value);
  };

  const removeSearch = () => {
    productListing("", rentalType?.value, page, perPage);
    setSearch("");
  };

  const handleViewRentel = (data) => {
    navigate(`/renter/my-rental-detail?id=${data?.id}&pid=${data?.product_id}`);
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}

      <div className="mainbox_outer">
        <RenterProfileTabs active="my-rental" />
        <div className="top-breadcrumb top-area ">
          <Container>
            <Row className="align-items-center">
              <Col lg={4} md={3} sm={12} className="mb-3 mb-lg-0">
                <Form className="search-form">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="Search"
                    aria-label="Search"
                    value={search}
                    onChange={handleSearch}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span className="search-icon">
                    <SearchGrayIcon />
                  </span>
                  {search.length ? (
                    <span className="cross" onClick={removeSearch}>
                      <CrossDarkIcon />
                    </span>
                  ) : (
                    ""
                  )}
                </Form>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <Form.Label>Status</Form.Label>
                <Dropdown
                  name="my_rental"
                  value={rentalType}
                  clearable={true}
                  options={[
                    { label: "Current", value: CURRENT },
                    { label: "Upcoming", value: UPCOMING },
                    { label: "Past", value: PAST },
                  ]}
                  placeholder={"Select"}
                  onChange={(e) => handleRentalTypeChange(e)}
                />
              </Col>
              {/* <Col lg={2} md={3} sm={12}>
              <Button className="btn btn-theme mb-0  d-lg-block d-none">
                Apply Filters
              </Button>
            </Col> */}
            </Row>
          </Container>
        </div>
        <div className="accountmain">
          <div className="category-boxes heightauto my-4 ">
            <Container>
              <Row>
                {list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <Col lg={3} md={4} sm={12} className="mb-3" key={index}>
                        <Card className="mb-4" style={{ cursor: "pointer" }}>
                          {/* Pending Badge */}
                          {data?.delivery_options === SELF && (
                            <>
                              {data?.request_status === SELF_DELIVERY_REQUEST_PENDING && (
                                <span className="badge badge-pending bg-warning">Awaiting Approval</span>
                              )}
                              {data?.request_status === SELF_DELIVERY_REQUEST_ACCEPTED && (
                                <span className="badge badge-pending bg-theme font-dark">Accepted by Lender</span>
                              )}
                              {data?.request_status === SELF_DELIVERY_REQUEST_REJECTED && (
                                <span className="badge badge-pending bg-danger">Rejected by Lender</span>
                              )}
                            </>
                          )}
                          <Card.Img
                            variant="top"
                            onClick={() => handleViewRentel(data)}
                            src={
                              data?.product_image?.images
                                ? imageBase + data?.product_image?.images
                                : require("../../assets/images/static_image.jpg")
                            }
                          />
                          <Card.Body>
                            <div
                              className="d-flex align-items-center gap-3 img-box mb-3"
                              onClick={() =>
                                navigate(
                                  `/renter/user-profile?user_id=${data?.created_by?.id}`
                                )
                              }
                            >
                              {data?.created_by_image ? (
                                <img
                                  src={
                                    data?.created_by_image
                                      ? imageBase + data?.created_by_image
                                      : require("../../assets/images/DummyImg.png")
                                  }
                                  className="avtar rounded-circle"
                                  alt="Img"
                                />
                              ) : (
                                <DefaultProfileImg userDetails={splitFullName(data?.created_by?.full_name)} />
                              )}
                              <h6 className="mb-0">
                                {data?.created_by?.full_name}
                              </h6>
                            </div>
                            <p className="font12 font700 mb-2">
                              <i>
                                {moment(data?.booked_from).format("DD-MMM-YYYY")}
                                {" "}{"to"}{" "}
                                {moment(data?.booked_to).format("DD-MMM-YYYY")}
                              </i>                              
                            </p>
                            <div onClick={() => handleViewRentel(data)}>
                              <h5 className="head16">{data?.title}</h5>
                              <p>
                                <Link className="colordark font700 font12 mb-0 btn-shop">
                                  See More Details{" "}
                                  <span className="ms-2">
                                    <BtnRightIcon />{" "}
                                  </span>
                                </Link>
                              </p>
                            </div>

                            {/* <p className="mb-0">
                              {moment(data?.booked_to).format("YYYY-MM-DD") <
                                moment().format("YYYY-MM-DD") && (
                                <>
                                  <Link
                                    to={`/renter/write-review?id=${data?.id}&pid=${data?.product_id}`}
                                    className="colordark font700 font12 mb-0 btn-shop"
                                  >
                                    Write Review
                                    <span className="ms-2">
                                      <ReviewCommentIcon />
                                    </span>
                                  </Link>
                                </>
                              )}
                            </p> */}
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <>{!loading && <Nodata />} </>
                )}
              </Row>
              <div className="d-flex align-items-center justify-content-between m-4">
                <Paginate
                  page={meta?.page_count}
                  handlePageClick={(e) => handlePageChange(e)}
                  cp={page}
                />
                <div className="d-flex align-items-center justify-content-end flex-wrap gap-2 mb-3">
                  <h6 className="font12 font400 colordark mb-0">Show</h6>
                  <div style={{ width: "70px" }}>
                    <Form.Select
                      aria-label="Default select"
                      className="font14 font400"
                      value={perPage}
                      onChange={handlePerPageChange}
                    >
                      <option value={PERPAGE_TEN}>10</option>
                      <option value={PERPAGE_TWENTY}>20</option>
                      <option value={PERPAGE_THIRTY}>30</option>
                    </Form.Select>
                  </div>
                  <h5 className="font12 font400 colordark mb-0">per page</h5>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
      {loading ? <Loader /> : null}
    </>
  );
};

export default MyRentals;
