/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import Nodata from "../../Commons/CommonModals/Nodata";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import ProfileTabs from "../../Commons/ProfileTabs";
import {
  AddProductAvailability,
  availavlityDetailsPerDay,
  calenderProducts,
  getProductDetails,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import {
  BackIcon,
  CalendarIcon,
  CrossDarkIcon,
  CrossRedIcon,
  FilterIcon,
  WarningIcon
} from "../../SvgIcons/allIcons";
import {
  DEFAULT_CURRENCY,
  LENDER,
  LIST_AVAILABILITY_AVAILABLE,
  LIST_AVAILABILITY_BLOCKED,
  RENTER,
  SELF,
  SELF_DELIVERY_REQUEST_PENDING
} from "../../globals/constants";
import useRole from "../../hooks/useRole";
import { imageBase } from "../../globals/Config";
import DefaultProfileImg from "../../Commons/DefaultProfileImg";
import { splitFullName } from "../../globals/HelperCommon";

const Calendar = () => {
  const location = useLocation();
  const role = useRole();
  const rid = new URLSearchParams(location?.search).get("rid");
  const [list, setList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [productId, setProductId] = useState("");
  const [events, setEvents] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [bookingDate, setBookingDate] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);

  const calendarRef = useRef(null);
  const calendarRefMobile = useRef(null);
  let clickedDays = document.querySelectorAll(".selectedDate");

  useEffect(() => {
    productListing();
    if (rid) {
      ProductDetails(rid);
      setProductId(rid);
      AvailabilityDetails(rid);
    }
  }, [rid]);

  useEffect(() => {
    if (productId && product) {
      AvailabilityDetails(productId);
    }
  }, [productId, product]);

  const productListing = () => {
    calenderProducts()
      .then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setList(res?.data?.data);
        }
      })
      .finally(() => setLoading(false));
  };

  const ProductDetails = (id) => {
    getProductDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setProduct(resp?.data?.data);
        // Get and Set daily price in state : in formic
        setSelectedMonth({
          label: moment().format("MMMM YYYY"),
          value: moment().format("YYYY-MM-01"),
        });
        updateCalendarMonth(moment().format("YYYY-MM-01"));
      }
      else {
        setProduct([]);
      }
    });
  };

  /**
   * Generate array of one year from current date
   * @returns array
   */
  const generateYearDates = () => {
    const dates = [];
    const startMonth = moment().subtract(15, 'days'); // Start from 15 days back
    const endMonth = startMonth.clone().add(12, 'months');

    let currentMonth = startMonth.clone();
    while (currentMonth < endMonth) {
      const startDate = currentMonth.clone().startOf('month');
      const endDate = currentMonth.clone().endOf('month');

      let currentDate = startDate.clone();
      while (currentDate <= endDate) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate.add(1, 'day');
      }

      currentMonth.add(1, 'month');
    }

    return dates;
  };

  const AvailabilityDetails = async (id, month = moment().format("MM"), year = moment().format("YYYY")) => {

    const yearDates = generateYearDates(); // Generate array of one year
    try {
      const resp = await availavlityDetailsPerDay(id, month, year);
      const { status, data } = resp?.data || {};

      if (status === 200) {
        console.log('Response Data:', data);

        const eventData = yearDates.map(date => {

          //const eventData = Object.keys(data?.selected_dates).map(date => {
          const selectedDateData = data.selected_dates ? data.selected_dates[date] : [];

          if (selectedDateData && selectedDateData.availability_status) {
            return {
              date: moment(date).format("YYYY-MM-DD"),
              className: "single_day",
              display: "background",
              price: selectedDateData.product_price ?? product?.daily_price ?? '',
            };
          } else if (selectedDateData && selectedDateData.availability_status === false) {
            return {
              date: moment(date).format("YYYY-MM-DD"),
              className: "blocked_class",
              display: "background",
            };

          } else {
            // Default case for dates not in selected_dates
            return {
              date,
              className: "single_day",
              display: "background",
              price: product?.daily_price ?? '',
            };
          }
        });
        setEvents(eventData);

        if (data?.booked_data?.length > 0) {
          const dates = [];
          const multipleBookings = [];

          data.booked_data.forEach((booking) => {
            let { from_date: fromDate, to_date: toDate, estimated_return_delivery_days } = booking;
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            let bufferDays = estimated_return_delivery_days || 0;

            // Block buffer days before the booking
            let preBufferDate = new Date(fromDate);
            for (let i = 0; i < bufferDays; i++) {
              preBufferDate.setDate(preBufferDate.getDate() - 1);
              dates.push(moment(preBufferDate).format("YYYY-MM-DD"));
              multipleBookings.push({
                className: "multiple_day buffer_day_before rounded-pill-shipping",
                start: moment(preBufferDate).format("YYYY-MM-DD"),
                end: moment(preBufferDate).format("YYYY-MM-DD"),
                display: "background",
                booking_data: booking,
              });
            }
            // Block buffer days after the booking
            let postBufferDate = new Date(toDate);
            for (let i = 0; i < bufferDays; i++) {
              postBufferDate.setDate(postBufferDate.getDate() + 1);
              dates.push(moment(postBufferDate).format("YYYY-MM-DD"));
              multipleBookings.push({
                className: "multiple_day buffer_day_after rounded-pill-shipping",
                start: moment(postBufferDate).format("YYYY-MM-DD"),
                end: moment(postBufferDate).format("YYYY-MM-DD"),
                display: "background",
                booking_data: booking,
              });
            }

            let currentDate = new Date(fromDate);
            while (currentDate <= toDate) {
              dates.push(moment(currentDate).format("YYYY-MM-DD"));
              currentDate.setDate(currentDate.getDate() + 1);
            }

            multipleBookings.push({
              className: "multiple_day rounded-pill-start",
              start: moment(fromDate).format("YYYY-MM-DD"),
              end: moment(toDate.setDate(toDate.getDate() + 1)).format("YYYY-MM-DD"),
              booking_data: booking,
            });
          });

          setBookingDate(dates);
          setEvents([...eventData, ...multipleBookings]);
        } else {
          setBookingDate([]);
        }
      }

    } catch (error) {
      setBookingDate([]);
      setEvents([]);
      console.error("Error:", error);
    }
  };

  const validationSchema = Yup.object({
    available_from: Yup.string().when(
      "selected_date",
      ([selected_date], schema) => {
        if (!selected_date?.length) {
          return Yup.string().required("From date required");
        }
        return schema;
      }
    ),
    available_to: Yup.string().when(
      "selected_date",
      ([selected_date], schema) => {
        if (!selected_date?.length) {
          return Yup.string().required("To date required");
        }
        return schema;
      }
    ),
    availability_status: Yup.string().required("availability status required"),
    product_price: Yup.string().when(
      "availability_status",
      ([availability_status], schema) => {
        if (availability_status == LIST_AVAILABILITY_AVAILABLE) {
          return Yup.number()
            .typeError()
            .min(1)
            .label("Product price")
            .max(999999, "Must be exactly 6 digits")
            .required("Price is required")
            .test(
              "Is positive?",
              "The price must be greater than 0!",
              (value) => value > 0
            );
        }
        return schema;
      }
    ),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      product_id: productId,
      available_from: "",
      available_to: "",
      availability_status: "",
      product_price: "",
      selected_date: [],
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("product_id", productId);
      formData.append("available_from", values.available_from);
      formData.append("available_to", values.available_to);
      formData.append(
        "product_price",
        values.product_price ? values.product_price : product?.daily_price
      );
      formData.append("availability_status", values?.availability_status);
      formData.append("selected_date", values?.selected_date);
      formData.append("is_separate_dates", values.selected_date?.length ? 0 : 1);
      setLoading(true);
      AddProductAvailability(formData)
        .then((resp) => {
          setEditShow(false);
          setSubmitting(false);
          setLoading(false);
          if (resp?.data?.status === 200) {
            AvailabilityDetails(
              resp?.data?.data?.product,
              moment(selectedMonth?.value).format("MM")
            );
            updateCalendarMonth(selectedMonth?.value);
            setValues({
              ...values,
              available_from: "",
              available_to: "",
              selected_date: [],
            });
            setFieldValue("available_from", "");
            setFieldValue("available_to", "");
            setFieldTouched("available_from", false);
            setFieldTouched("available_to", false);
            setShowSidebar(false);
            clickedDays.forEach((day) => {
              day.classList.remove("selectedDate");
            });
          } else if (resp?.status === 400) {
            productListing();
            handleReset();
            return;
          } else {
            setValues({
              ...values,
              available_from: "",
              available_to: "",
              selected_date: [],
            });
            setFieldValue("available_from", "");
            setFieldValue("product_price", "");
            setFieldValue("available_to", "");
            setFieldTouched("available_from", false);
            setFieldTouched("available_to", false);
            setShowSidebar(false);
            clickedDays.forEach((day) => {
              day.classList.remove("selectedDate");
            });
          }
        })
        .catch((err) => console.log(err));
    },
  });

  /**
   *
   * Apply button
   *
   */

  const handleEvent = () => {
    if (productId) {
      setFieldTouched("available_from");
      setFieldTouched("available_to");
      if (values?.available_from && values?.available_to) {
        clickedDays.forEach((day) => {
          day.classList.remove("selectedDate");
        });

        let startDate = new Date(values?.available_from);
        let endDate = new Date(values?.available_to);
        let eventArray = [];

        for (
          startDate;
          startDate <= endDate;
          startDate.setDate(startDate.getDate() + 1)
        ) {
          eventArray.push({
            date: moment(startDate)?.format("YYYY-MM-DD"),
            className: "selectedDate",
            display: "background",
          });
        }
        setEvents(eventArray);
        updateCalendarMonth(
          moment(values?.available_from).format("YYYY-MM-01")
        );
        setSelectedMonth({
          label: moment(values?.available_from).format("MMMM YYYY"),
          value: moment(values?.available_from).format("YYYY-MM-01"),
        });
        setShowSidebar(true);
        // setIsShowForm(false);
      }

      setFieldValue("product_price", product?.daily_price);
    } else {
      showNotification("danger", "Please select Product");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  const handleReset = () => {
    setEvents([]);
    setIsSelected(false);
    setShowSidebar(false);
    clickedDays.forEach((day) => {
      day.classList.remove("selectedDate");
    });
    setFieldValue("selected_date", []);
    setFieldValue("available_from", "");
    setFieldValue("available_to", "");
    setFieldTouched("available_from", false);
    setFieldTouched("available_to", false);
    AvailabilityDetails(productId, moment(selectedMonth?.value).format("MM"));
  };

  const handleDateClick = (info) => {

    const clickedEvent = events.find((event) => event.date == info?.dateStr);
    const price = clickedEvent ? clickedEvent?.price : product?.daily_price;
    setFieldValue("product_price", price);

    if (bookingDate?.includes(info?.dateStr)) {
      return false;
    } else {
      if (values?.selected_date?.includes(info?.dateStr)) {
        let newDates = values?.selected_date?.filter((dates) => {
          return info?.dateStr != dates;
        });
        setFieldValue("selected_date", newDates);
        info.dayEl.classList.remove("selectedDate");
      } else {
        setFieldValue("selected_date", [
          ...values?.selected_date,
          info?.dateStr,
        ]);
        info.dayEl.classList.add("selectedDate");
      }
    }
  };

  /**********************Month change functionality and designs*******************/

  const [selectedMonth, setSelectedMonth] = useState({
    label: moment().format("MMMM YYYY"),
    value: moment().format("YYYY-MM-01"),
  });

  const MONTHS = () => {
    const months = [];
    const dateStart = moment().subtract(1, "month");
    const dateEnd = moment().add(12, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push({
        label: dateStart.format("MMMM YYYY"),
        value: dateStart.format("YYYY-MM-01"),
      });
      dateStart.add(1, "month");
    }
    return months;
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event);
    updateCalendarMonth(event?.value);
    AvailabilityDetails(
      productId,
      moment(event?.value)?.format("MM"),
      moment(event?.value)?.format("YYYY")
    );
  };

  const updateCalendarMonth = (date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }

    setTimeout(() => {
      if (calendarRefMobile.current) {
        const calendarApi = calendarRefMobile.current.getApi();
        calendarApi.gotoDate(date);
      }
    }, 250);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #E8E8E8",
      backgroundColor: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E8E8E8",
      },
      outline: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const navigate = useNavigate();

  const handleReservation = (data) => {
    navigate(
      `/lender/reservation-detail?id=${data?.order_id}&pid=${data?.product_id}`
    );
  };

  /***********************To Show Different Date Groups while selecting dates***********************/

  const sortedDates = values?.selected_date?.sort(
    (a, b) => moment(a) - moment(b)
  );

  const getContinuousDateGroups = (dates) => {
    const result = [];
    let currentGroup = [dates[0]];

    for (let i = 1; i < dates.length; i++) {
      const currentDate = moment(dates[i]);
      const prevDate = moment(dates[i - 1]);

      if (currentDate.diff(prevDate, "days") === 1) {
        currentGroup.push(dates[i]);
      } else {
        result.push(currentGroup);
        currentGroup = [dates[i]];
      }
    }

    result.push(currentGroup);

    return result;
  };

  const continuousDateGroups = getContinuousDateGroups(sortedDates);

  return (
    <>
      <div className="d-md-block d-none">
        {role == LENDER ? (
          <LenderHeader />
        ) : role == RENTER ? (
          <RenterHeader />
        ) : (
          <Header />
        )}
        <div className="mainbox_outer ">
          <ProfileTabs active="calendar" />
          <div className="accountmain bggrey">
            <div className="category-boxes heightauto">
              <Container fluid>
                <Row className="d-flex flex-nowrap">
                  <Col lg={2} md={3} sm={12} className="green-border produt-list px-lg-0" >
                    {list && list.length > 0 ? (
                      list &&
                      list?.map((data, index) => {
                        return (
                          <div
                            className="room"
                            onClick={() => {
                              setProductId(data?.id);
                              ProductDetails(data?.id);
                              setBookingDate("");
                            }}
                            key={index}
                          >
                            <img
                              className={productId == data?.id ? "active" : ""}
                              src={
                                data?.product_image?.images
                                  ? imageBase + data?.product_image?.images
                                  : require("../../assets/images/static_image.jpg")
                              }
                              alt="img"
                            />

                            <h4 className="font400 text-capitalize">
                              {data?.title}
                            </h4>
                          </div>
                        );
                      })
                    ) : (
                      <>{!loading && <Nodata />}</>
                    )}
                  </Col>

                  <Col lg={10} md={9} sm={12}>
                    <Row>
                      <Col lg={10} className=" bg-white">
                        <Col lg={2}>
                          <div className="my-2">
                            <Select
                              name="month_change"
                              options={MONTHS()}
                              value={selectedMonth}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              onChange={handleMonthChange}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                  ...theme.colors,
                                  primary25: "rgb(22 255 150 / 24%)",
                                  primary: "rgba(22, 255, 150, 0.1)",
                                },
                              })}
                              styles={customStyles}
                              className="dropdown-role"
                            />
                          </div>
                        </Col>
                        <div className="top-breadcrumb top-area my-3">
                          <Container>
                            <Row className="align-items-start ">
                              <Col lg={5} md={5} sm={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="font700 font12 secondaycolor">
                                    From
                                  </Form.Label>
                                  <div className="datepicker position-relative">
                                    <Form.Control
                                      type="date"
                                      placeholder="DD/MM/YYYY"
                                      name="available_from"
                                      value={values?.available_from}
                                      className="available_from"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyDown={handleKeyDown}
                                      min={moment().format("YYYY-MM-DD")}
                                    />
                                    {touched?.available_from &&
                                      errors?.available_from && (
                                        <span className="text-start required">
                                          {errors?.available_from}
                                        </span>
                                      )}
                                    <span className="position-absolute">
                                      <CalendarIcon />
                                    </span>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg={5} md={5} sm={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="font700 font12 secondaycolor">
                                    To
                                  </Form.Label>

                                  <div className="datepicker position-relative">
                                    <Form.Control
                                      type="date"
                                      placeholder="DD/MM/YYYY"
                                      name="available_to"
                                      value={values?.available_to}
                                      className="available_to"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onKeyDown={handleKeyDown}
                                      min={values?.available_from}
                                    />
                                    {touched?.available_to &&
                                      errors?.available_to && (
                                        <span className="text-start required">
                                          {errors?.available_to}
                                        </span>
                                      )}
                                    <span className="position-absolute">
                                      <CalendarIcon />
                                    </span>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg={2} md={2} sm={12} className="mt-2">
                                <Button
                                  className="btn btn-theme mb-0 mt-4"
                                  onClick={() => {
                                    handleEvent();
                                  }}
                                >
                                  Apply
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                        <div className="calender main-calender-grey pb-3">
                          <FullCalendar
                            ref={calendarRef}
                            themeSystem="Simplex"
                            headerToolbar={false}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            dateClick={handleDateClick}
                            events={events}
                            eventContent={(info) => {
                              let data = info?.event?.extendedProps?.booking_data;
                              let price = info?.event?.extendedProps?.price;

                              return (
                                <>
                                  <div
                                    className="booking"
                                    onClick={() => handleReservation(data)}
                                  >
                                    {data?.profile_pic ? (
                                      <img
                                        width="40"
                                        height="40"
                                        style={{
                                          borderRadius: "50%",
                                          marginRight: "10px",
                                        }}
                                        src={
                                          data?.profile_pic
                                            ? imageBase + data?.profile_pic
                                            : require("../../assets/images/DummyImg.png")
                                        }
                                      />
                                    ) : (
                                      <DefaultProfileImg userDetails={splitFullName(data?.username)} />
                                    )}
                                    <div className="high-dis">
                                      <b
                                        style={{
                                          marginBottom: "-8px",
                                        }}
                                      >
                                        {data?.username}
                                      </b>
                                      <i>
                                        Payment:{DEFAULT_CURRENCY}
                                        {data?.price
                                          ? data?.price &&
                                          Number(data?.price).toLocaleString(
                                            "hi"
                                          )
                                          : ''}
                                      </i>
                                      {data?.delivery_options === SELF && (
                                        <>
                                          {data?.request_status === SELF_DELIVERY_REQUEST_PENDING && (
                                            <span className="font12 text-danger ms-4 text-decoration-underline">
                                              Waiting for approval
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="price">
                                    <p>
                                      {price && (
                                        <>
                                          {DEFAULT_CURRENCY}
                                          {Number(price).toLocaleString("hi")}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </>
                              );
                            }}
                          />
                        </div>
                      </Col>

                      {(values?.selected_date?.length > 0 || showSidebar) && (
                        <Col lg={2}>
                          <div
                            className="text-end py-3 "
                            onClick={() => handleReset()}
                          >
                            <CrossRedIcon />
                          </div>
                          <div className="calender-select">
                            {values?.selected_date?.length > 0 ? (
                              <h4 className="font24 font700 colordark mb-5">
                                {continuousDateGroups
                                  ?.map((arr, i) => {
                                    if (arr?.length > 1) {
                                      return `
                                      ${moment(arr[0]).format("DD MMM")} -
                                      ${moment(arr[arr.length - 1]).format(
                                        "DD MMM"
                                      )}`;
                                    }
                                    return moment(arr[0]).format("DD MMM");
                                  })
                                  ?.join(", ")}
                              </h4>
                            ) : (
                              <h4 className="font24 font700 colordark mb-5">
                                {values?.available_from
                                  ? moment(values?.available_from).format(
                                    "DD MMMM "
                                  )
                                  : "Start date "}
                                {" - "}
                                {values?.available_to
                                  ? moment(values?.available_to).format(
                                    "DD MMMM "
                                  )
                                  : " End Date"}
                              </h4>
                            )}

                            <p className="font12 font700">Availability</p>

                            <div className="d-flex mb-2 align-items-center">
                              <div className="custom-checkbox mt-1">
                                <Form.Check
                                  type="radio"
                                  checked={
                                    values?.availability_status ==
                                    LIST_AVAILABILITY_AVAILABLE
                                  }
                                  value={LIST_AVAILABILITY_AVAILABLE}
                                  name="availability_status"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="available"
                                />
                              </div>

                              <label
                                className="font16 font400 mb-0 ms-2"
                                htmlFor="available"
                              >
                                Available
                              </label>
                            </div>
                            <div className="d-flex mb-2 align-items-center">
                              <div className="custom-checkbox mt-1">
                                <Form.Check
                                  type="radio"
                                  checked={
                                    values?.availability_status ==
                                    LIST_AVAILABILITY_BLOCKED
                                  }
                                  value={LIST_AVAILABILITY_BLOCKED}
                                  name="availability_status"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="blocked"
                                />
                              </div>
                              <label
                                className="font16 font400 mb-0 ms-2"
                                htmlFor="blocked"
                              >
                                Blocked
                              </label>
                            </div>

                            <span className="text-start required">
                              {touched?.availability_status &&
                                errors?.availability_status}
                            </span>
                            {values?.availability_status ==
                              LIST_AVAILABILITY_AVAILABLE && (
                                <div className="mb-2">
                                  <p className="font12 font700 colordark mb-1">
                                    Pricing
                                  </p>
                                  <Form.Control
                                    type="number"
                                    name="product_price"
                                    value={values?.product_price}
                                    placeholder=" Price"
                                    className="product_price"
                                    onChange={handleChange}
                                    // maxLength={7}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown}
                                  />
                                  {touched?.product_price &&
                                    errors?.product_price && (
                                      <span className="text-start required">
                                        {errors?.product_price}
                                      </span>
                                    )}
                                </div>
                              )}

                            <div className="mt-3">
                              <Link
                                className="btn btn-theme mb-0 w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              >
                                Save
                              </Link>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {/*************************************************Mobile Screen***********************************/}
      <div className="d-block d-md-none ">
        <div className="fixed_header">
          {role == LENDER ? (
            <LenderHeader />
          ) : role == RENTER ? (
            <RenterHeader />
          ) : (
            <Header />
          )}
        </div>
        <div className="mainbox_outer main_inner">
          <ProfileTabs active="calendar" />
          <div className="accountmain bggrey pb-0 mb-5">
            <div className="category-boxes heightauto">
              <Container fluid>
                <Row className="d-flex flex-nowrap">
                  {isSelected == false && (
                    <Col lg={2} md={6} sm={12} className="green-border px-lg-0">
                      {list && list.length > 0 ? (
                        list &&
                        list?.map((data, index) => {
                          return (
                            <div
                              className="room"
                              onClick={() => {
                                setProductId(data?.id);
                                ProductDetails(data?.id);
                                setIsSelected(true);
                                setBookingDate("");
                              }}
                              key={index}
                            >
                              <img
                                className={
                                  productId == data?.id ? "active" : ""
                                }
                                src={
                                  data?.product_image?.images
                                    ? imageBase + data?.product_image?.images
                                    : require("../../assets/images/static_image.jpg")
                                }
                                alt="img"
                              />

                              <h4 className="font400 text-capitalize">
                                {data?.title}
                              </h4>
                            </div>
                          );
                        })
                      ) : (
                        <>{!loading && <Nodata />}</>
                      )}
                    </Col>
                  )}
                  {isSelected && (
                    <Col lg={10} md={6} sm={12}>
                      <Row>
                        {!editShow && (
                          <Col lg={10} className=" bg-white">
                            <Col lg={2}>
                              <p className="head20 d-flex align-items-center gap-3 pt-4">
                                <Link
                                  className="d-block d-lg-none d-md-none"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsSelected(false);
                                    clickedDays.forEach((day) => {
                                      day.classList.remove("selectedDate");
                                    });
                                  }}
                                >
                                  <BackIcon />
                                </Link>
                                {product?.title}
                              </p>
                              <div className="my-2 d-flex align-items-center justify-content-between">
                                <Select
                                  name="month_change"
                                  options={MONTHS()}
                                  value={selectedMonth}
                                  menuPortalTarget={document.body}
                                  menuPosition={"fixed"}
                                  onChange={handleMonthChange}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "rgb(22 255 150 / 24%)",
                                      primary: "rgba(22, 255, 150, 0.1)",
                                    },
                                  })}
                                  styles={customStyles}
                                  className="dropdown-role w-100 me-2"
                                />

                                <Link
                                  className="btn btn-green"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsShowForm((prev) => !prev);
                                  }}
                                >
                                  {isShowForm ? (
                                    <CrossDarkIcon />
                                  ) : (
                                    <FilterIcon />
                                  )}
                                </Link>
                              </div>
                            </Col>
                            {isShowForm && (
                              <div className="top-breadcrumb top-area my-3">
                                <Container>
                                  <Row className="align-items-start">
                                    <Col lg={5} md={5} sm={12}>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="font700 font12 secondaycolor">
                                          From
                                        </Form.Label>
                                        <div className="datepicker position-relative">
                                          <Form.Control
                                            type="date"
                                            placeholder="DD/MM/YYYY"
                                            name="available_from"
                                            value={values?.available_from}
                                            className="available_from"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            min={moment().format("YYYY-MM-DD")}
                                          />
                                          {touched?.available_from &&
                                            errors?.available_from && (
                                              <span className="text-start required">
                                                {errors?.available_from}
                                              </span>
                                            )}
                                          <span className="position-absolute">
                                            <CalendarIcon />
                                          </span>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={5} md={5} sm={12}>
                                      <Form.Group className="mb-3">
                                        <Form.Label className="font700 font12 secondaycolor">
                                          To
                                        </Form.Label>

                                        <div className="datepicker position-relative">
                                          <Form.Control
                                            type="date"
                                            placeholder="DD/MM/YYYY"
                                            name="available_to"
                                            value={values?.available_to}
                                            className="available_to"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            min={values?.available_from}
                                          />
                                          {touched?.available_to &&
                                            errors?.available_to && (
                                              <span className="text-start required">
                                                {errors?.available_to}
                                              </span>
                                            )}
                                          <span className="position-absolute">
                                            <CalendarIcon />
                                          </span>
                                        </div>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={2} md={2} sm={12}>
                                      <Button
                                        className="btn btn-theme mb-0 mt-3"
                                        onClick={() => {
                                          handleEvent();
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            )}

                            <div className="calender pb-3">
                              <FullCalendar
                                ref={calendarRefMobile}
                                themeSystem="Simplex"
                                headerToolbar={false}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                dateClick={handleDateClick}
                                events={events}
                                eventContent={(info) => {
                                  let data = info?.event?.extendedProps?.booking_data;
                                  let price = info?.event?.extendedProps?.price;
                                  return (
                                    <>
                                      <div
                                        className="booking"
                                        onClick={() => handleReservation(data)}
                                      >
                                        {data?.profile_pic ? (
                                          <img
                                            width="20"
                                            height="20"
                                            className="rounded-circle me-2"
                                            src={
                                              data?.profile_pic
                                                ? imageBase + data?.profile_pic
                                                : require("../../assets/images/DummyImg.png")
                                            }
                                          />
                                        ) : (
                                          <DefaultProfileImg userDetails={splitFullName(data?.username)} />
                                        )}
                                        <div className="high-dis">
                                          <span
                                            style={{
                                              marginBottom: "-8px",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {data?.username}
                                          </span>
                                          <span className="ms-2"> <WarningIcon /></span>
                                        </div>
                                      </div>
                                      <div className="price">
                                        <p>
                                          {price && (
                                            <>
                                              {DEFAULT_CURRENCY}
                                              {Number(price).toLocaleString("hi")}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                    </>
                                  );
                                }}
                              />
                            </div>
                            {(values?.selected_date?.length > 0 ||
                              showSidebar) && (
                                <div className="d-flex align-items-center gap-3 my-3">
                                  <Link
                                    className="btn btn-theme-outline mb-0 w-100"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleReset();
                                    }}
                                  >
                                    Cancel
                                  </Link>
                                  <Link
                                    className="btn btn-theme mb-0 w-100"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditShow(true);
                                      clickedDays.forEach((day) => {
                                        day.classList.remove("selectedDate");
                                      });
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </div>
                              )}
                          </Col>
                        )}

                        {editShow && (
                          <>
                            <Col lg={2}>
                              <div className="my-4">
                                <p
                                  className="fa-check"
                                  onClick={() => {
                                    setEditShow(false);
                                    handleReset();
                                  }}
                                >
                                  X
                                </p>
                                <div>
                                  {values?.selected_date.length > 0 ? (
                                    <h4 className="font24 font700 colordark mb-5">
                                      {continuousDateGroups
                                        ?.map((arr, i) => {
                                          if (arr?.length > 1) {
                                            return `
                                      ${moment(arr[0]).format("DD MMM")} -
                                      ${moment(arr[arr.length - 1]).format(
                                              "DD MMM"
                                            )}`;
                                          }
                                          return moment(arr[0]).format(
                                            "DD MMM"
                                          );
                                        })
                                        ?.join(", ")}
                                    </h4>
                                  ) : (
                                    <h4 className="font24 font700 colordark mb-5">
                                      {values?.available_from
                                        ? moment(values?.available_from).format(
                                          "DD MMMM "
                                        )
                                        : "Start date "}
                                      {" - "}
                                      {values?.available_to
                                        ? moment(values?.available_to).format(
                                          "DD MMMM "
                                        )
                                        : " End Date"}
                                    </h4>
                                  )}
                                  <div className="text-start py-3">
                                    <label>Daily price</label>
                                    <Form.Control
                                      type="number"
                                      name="product_price"
                                      value={values?.product_price}
                                      placeholder=" Price Per Day"
                                      className="product_price"
                                      onChange={handleChange}
                                      maxLength={7}
                                      onBlur={handleBlur}
                                      onKeyDown={handleKeyDown}
                                    />
                                    {touched?.product_price &&
                                      errors?.product_price && (
                                        <span className="text-start required">
                                          {errors?.product_price}
                                        </span>
                                      )}{" "}
                                  </div>

                                  <p className="font12 font700">Availability</p>

                                  <div className="d-flex mb-2 align-items-center">
                                    <div className="custom-checkbox">
                                      <Form.Check
                                        type="radio"
                                        value={LIST_AVAILABILITY_AVAILABLE}
                                        checked={
                                          values?.availability_status ==
                                          LIST_AVAILABILITY_AVAILABLE
                                        }
                                        name="availability_status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>

                                    <p className="font16 font400 mb-0 ms-2">
                                      Open
                                    </p>
                                  </div>
                                  <div className="d-flex mb-2 align-items-center">
                                    <div className="custom-checkbox">
                                      <Form.Check
                                        type="radio"
                                        value={LIST_AVAILABILITY_BLOCKED}
                                        checked={
                                          values?.availability_status ==
                                          LIST_AVAILABILITY_BLOCKED
                                        }
                                        name="availability_status"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>

                                    <p className="font16 font400 mb-0 ms-2">
                                      Closed
                                    </p>
                                  </div>
                                  {touched?.availability_status &&
                                    errors?.availability_status && (
                                      <span className="text-start required">
                                        {errors?.availability_status}
                                      </span>
                                    )}

                                  <div className="mt-3">
                                    <button
                                      type="button"
                                      className="btn btn-theme mb-0 w-100"
                                      disabled={isSubmitting || !isValid}
                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {loading ? <Loader /> : null}
    </>
  );
};

export default Calendar;
