/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import ProfileTabs from "../../Commons/ProfileTabs";
import { productAvailabilityDetails } from "../../Services/APIServices";
import { CalendarIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";

const ViewAvailability = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = useRole();
  const rid = new URLSearchParams(location?.search).get("id");
  const [productId, setProductId] = useState("");
  const [events, setEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({
    label: moment().format("MMMM YYYY"),
    value: moment().format("YYYY-MM-01"),
  });
  const calendarRef = useRef(null);

  useEffect(() => {
    if (rid) {
      setProductId(rid);
      AvailabilityDetails(rid, "");
    }
  }, []);

  const AvailabilityDetails = (id, month = "") => {
    productAvailabilityDetails(id, month).then((resp) => {
      if (resp?.data?.status === 200) {
        let eventsData = Object.entries(
          resp?.data?.data?.selected_dates || {}
        )?.map((data) => {
          if (data[1]) {
            return {
              date: moment(data[0])?.format("YYYY-MM-DD"),
              condition: data[1],
              className: "single_day",
              display: "background", // list-item
            };
          } else {
            return {
              date: moment(data[0])?.format("YYYY-MM-DD"),
              className: "blocked_class",
              display: "background",
            };
          }
        });
        setEvents(eventsData);
      }
    });
  };

  const MONTHS = () => {
    const months = [];
    const dateStart = moment().subtract(1, "month");
    const dateEnd = moment().add(12, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push({
        label: dateStart.format("MMMM YYYY"),
        value: dateStart.format("YYYY-MM-01"),
      });
      dateStart.add(1, "month");
    }
    return months;
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event);
    updateCalendarMonth(event?.value);
    AvailabilityDetails(productId, moment(event?.value)?.format("MM"));
  };

  const updateCalendarMonth = (date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
    }
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #E8E8E8",
      backgroundColor: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E8E8E8",
      },
      outline: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const validationSchema = Yup.object().shape({
    fromDate: Yup.date()
      .min(currentDate, "Please choose future date")
      .required("This field is required"),
    toDate: Yup.date()
      .when("fromDate", (fromDate, schema) => {
        if (fromDate[0] !== undefined) {
          let date = new Date(fromDate);
          date.setDate(date.getDate() + 1);
          return schema.min(date, "End date must be after start date");
        } else {
          return schema;
        }
      })
      .required("This field is required"),

    pinCode: Yup.number()
      .required("This field is required")
      .typeError("Invalid zip code").max(10, "Maximum 10 characters.").min(6, "Minimum 6 characters."),
  });

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        fromDate: "",
        toDate: "",
        pinCode: "",
      },
      validationSchema,
      onSubmit: () => {},
    });

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div>
        <div className="mainbox_outer ">
          <ProfileTabs active="calendar" />
          <div className="accountmain bggrey">
            <div className="category-boxes heightauto">
              <Container fluid>
                <Row>
                  <Col lg={8} className="mx-auto bg-white">
                    <Col lg={3}>
                      <div className="my-2">
                        <Select
                          name="month_change"
                          options={MONTHS()}
                          value={selectedMonth}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          onChange={handleMonthChange}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary25: "rgb(22 255 150 / 24%)",
                              primary: "rgba(22, 255, 150, 0.1)",
                            },
                          })}
                          styles={customStyles}
                          className="dropdown-role"
                        />
                      </div>
                    </Col>
                    <div className="top-breadcrumb top-area my-3">
                      <Container>
                        <Row className="align-items-start ">
                          <Col lg={3} md={3} sm={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                From
                              </Form.Label>
                              <div className="datepicker position-relative">
                                <Form.Control
                                  type="date"
                                  name="fromDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.fromDate}
                                  min={moment().format("YYYY-MM-DD")}
                                />
                                <span className="text-start required">
                                  {touched?.fromDate && errors?.fromDate}
                                </span>

                                <span className="position-absolute">
                                  <CalendarIcon />
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={3} md={3} sm={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                To
                              </Form.Label>

                              <div className="datepicker position-relative">
                                <Form.Control
                                  type="date"
                                  name="toDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.toDate}
                                  min={values?.fromDate}
                                />

                                <span className="text-start required">
                                  {touched?.toDate && errors?.toDate}
                                </span>

                                <span className="position-absolute">
                                  <CalendarIcon />
                                </span>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={3} md={3} sm={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font700 font12 secondaycolor">
                                Zip code
                              </Form.Label>

                              <div className="datepicker position-relative">
                                <Form.Control
                                  type="text"
                                  placeholder="Zip Code"
                                  name="pinCode"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  maxLength={6}
                                  onBlur={handleBlur}
                                  value={values?.pinCode}
                                />

                                <span className="text-start required">
                                  {touched?.pinCode && errors?.pinCode}
                                </span>
                              </div>
                            </Form.Group>
                          </Col>

                          <Col lg={2} md={2} sm={12} className="mt-2">
                            <Button
                              className="btn btn-theme mb-0 mt-4"
                              onClick={handleSubmit}
                            >
                              Proceed
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <div className="calender pb-3">
                      <FullCalendar
                        ref={calendarRef}
                        themeSystem="Simplex"
                        headerToolbar={false}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        events={events}
                        eventColor="rgba(22, 255, 150, 0.4)"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ViewAvailability;
