import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Selected } from "../../App";
import { UpdateContactInfo, UserDetails } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon, CrossIcon } from "../../SvgIcons/allIcons";
import { RENTER } from "../../globals/constants";
import { countryCode } from "../../utils/CountryCode";
import Dropdown from "../Dropdown";
import Loader from "../Loader/Loader";
import OtpInput from 'react-otp-input'
import { CheckCircle } from 'react-bootstrap-icons';

import { auth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from "../../firebase";

export default function ContactInformation() {
  const { setIsSelected } = useContext(Selected);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNum, setPhoneNum] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false)

  const phoneRegExp = /^[6-9]\d{9}$/;

  const urlTest = new RegExp(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gm
  );

  // Handle phone number change and reset OTP verification
  const handlePhoneNumberChange = (e) => {
    handleChange(e);
    setOtpVerified(false);
    setShowOtpInput(false);
    setOtp("");
    setProfileDetails({ ...profileDetails, is_phone_verified: 0 });
  };

  useEffect(() => {
    profileDetail();
  }, []);

  useEffect(() => {
    let recaptchaVerifier; // Declare a local reference to avoid using the window object

    if (!window.recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          setShow(true)
          console.log("Recaptcha verified", response);
        },
        "expired-callback": () => {
          showNotification("danger", "ReCaptcha expired. Please try again later.");
        },
      });

      window.recaptchaVerifier = recaptchaVerifier;
    }

    return () => {
      // Ensure recaptchaVerifier is still valid before clearing
      if (recaptchaVerifier && typeof recaptchaVerifier.clear === 'function') {
        try {
          recaptchaVerifier.clear();
          console.log("RecaptchaVerifier cleared successfully");
        } catch (error) {
          console.error("Error clearing RecaptchaVerifier:", error);
        }
      }
    };
  }, []);

  const validationSchema = Yup.object({
    country_code: Yup.string().required("This field is required."),
    mobile_no: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("This field is required."),
    linkedin: Yup.string().nullable().matches(urlTest, "URL is not valid"),
    facebook: Yup.string().nullable().matches(urlTest, "URL is not valid"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      email: profileDetails?.email,
      mobile_no: profileDetails?.mobile_no,
      linkedin: profileDetails?.linkedin,
      facebook: profileDetails?.facebook,
      country_code: profileDetails?.country_code,
      iso_code: profileDetails?.country_iso,
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let body = {
        email: values.email,
        mobile_no: values.mobile_no,
        linkedin: values.linkedin,
        facebook: values.facebook,
        country_code: values?.country_code,
        country_iso: values?.iso_code,
        is_phone_verified: otpVerified ? 1 : 0,
      };
      setLoading(true);
      UpdateContactInfo(body)
        .then((resp) => {
          setLoading(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            setProfileDetails(resp?.data?.data?.profile_pic);
            profileDetail();
            setIsSelected(false);
            if (userRole === RENTER) {
              navigate(`/renter/profile?atab=third`);
            } else {
              navigate(`/lender/profile?atab=third`);
            }
          } else {
            showNotification("danger", resp?.data?.message);
          }
        })
        .catch(console.log(errors));
    },
  });

  const profileDetail = () => {
    setLoading(true);
    UserDetails().then((resp) => {
      setLoading(false);
      setProfileDetails(resp?.data?.data);
      setValues({
        ...values,
        email: resp?.data?.data?.email,
        mobile_no: resp?.data?.data?.mobile_no,
        linkedin: resp?.data?.data?.linkedin,
        facebook: resp?.data?.data?.facebook,
        country_code: resp?.data?.data?.country_code ? resp?.data?.data?.country_code : "+91",
        iso_code: resp?.data?.data?.country_iso ? resp?.data?.data?.country_iso : "IN",
      });
      if (resp?.data?.data?.country_code) {
        setCountry({
          label: `${resp?.data?.data?.country_iso} (${resp?.data?.data?.country_code})`,
          value: resp?.data?.data?.country_code,
          iso: resp?.data?.data?.country_iso,
        });
      }else{
        setCountry({
          label: `${'IN'} (${'+91'})`,
          value: '+91',
          iso: 'IN',
        });
      }
    });
  };

  const countryCodeOptions = () => {
    let country_options = [];
    if (countryCode) {
      countryCode?.map((item) => {
        return country_options.push({
          label: `${item?.iso} (${item?.code})`,
          value: item?.code,
          iso: item?.iso,
        });
      });
      return country_options;
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e);
    setFieldValue("country_code", e?.value);
    setFieldValue("iso_code", e.iso);
  };

  const backAction = () => {
    if (userRole == RENTER) {
      navigate("/renter/profile?atab=first");
    } else {
      navigate("/lender/profile?atab=first");
    }
  };

  const handleSendOtp = async (phone) => {

    if (!phone || !phoneRegExp.test(phone)) {
      showNotification("danger", "Invalid phone number. Please enter a valid 10-digit phone number starting with 6-9.");
      return false;
    }

    setPhoneNum(phone); // Keep phone for resend otp
    setLoading(true);

    const phoneNumber = profileDetails?.country_code ? `${profileDetails?.country_code}${phone}` : `+91${phone}`;
    console.log('phoneNumber: ', phoneNumber);
    try {

      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      console.log('confirmationResult: ', confirmationResult.verificationId);
      setVerificationId(confirmationResult.verificationId);
      setShowOtpInput(true);

    } catch (error) {
      console.error("Error during signInWithPhoneNumber:", error);
      showNotification("danger", "Invalid OTP or verification credentials.");
    } finally {
      setLoading(false);
    }

  }
  const verifyOtp = async () => {

    if (otp && otp.length === 6) {
      setLoading(true);
      try {
        const credential = PhoneAuthProvider.credential(verificationId, otp);
        const userCredential = await signInWithCredential(auth, credential);

        // Success - Extract relevant data from the success object
        const { uid, phoneNumber, stsTokenManager } = userCredential.user;
        const { accessToken, refreshToken, expirationTime } = stsTokenManager;

        showNotification("success", "OTP verified successfully. Click on Sign Up to continue.");

        console.log("User ID:", uid);
        console.log("Phone Number:", phoneNumber);
        console.log("Access Token:", accessToken);
        console.log("Refresh Token:", refreshToken);
        console.log("Token Expiration:", expirationTime);

        setShow(false)
        setOtpVerified(true);
        setLoading(false);

        setTimeout(() => {
          handleSubmit(); // Update Contact Info
        }, 100);

      } catch (error) {
        setOtpVerified(false);
        setLoading(false);
        setShow(false)
        if (error.code === 'auth/invalid-verification-code') {
          showNotification("danger", "Invalid OTP. Please try again.");
        } else {
          console.error("Error during OTP verification:", error.message);
          showNotification("danger", error.message);
        }
      }
    } else {
      showNotification("danger", 'OTP must be 6 digits');
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <p className="head16 d-flex align-items-center gap-3 px-4 px-lg-0">
        <Link
          className="d-block d-lg-none d-md-none"
          onClick={(e) => {
            e.preventDefault();
            setIsSelected(false);
          }}
        >
          <BackIcon />
        </Link>
        Contact Information
      </p>
      <Row className="px-4 px-lg-0 personalinfo">
        <Col xxl={6} xl={6} lg={10} md={12} xs={12}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Email <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values?.email}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.email && errors.email}
              </span>
            </Form.Group>

            <Row>
              <Col xxl={4} xl={4} lg={4} md={3} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Country Code <span className="error">*</span>
                  </Form.Label>
                  <Dropdown
                    name="country_code"
                    value={country}
                    options={countryCodeOptions()}
                    onChange={handleCountryChange}
                    onBlur={() => setFieldTouched("country_code", true)}
                  />
                  <span className="text-start required">
                    {touched.country_code && errors.country_code}
                  </span>
                </Form.Group>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={9} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Phone Number <span className="error">*</span>
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      name="mobile_no"
                      maxLength={10}
                      value={values?.mobile_no}
                      onChange={handlePhoneNumberChange}
                      onBlur={handleBlur}
                      style={{ maxWidth: '435px' }}
                    />

                    {/** if not verified show Send OTP Button */}
                    {(!otpVerified && profileDetails?.is_phone_verified == 0 || profileDetails?.is_phone_verified == null) ? (
                      <Button
                        className="btn-theme mb-0 verify-btn ms-1"
                        onClick={() => handleSendOtp(values?.mobile_no)}
                      >
                        {loading && !showOtpInput ? "Sending OTP..." : " Verify"}
                      </Button>
                    ) : null}

                    {/** If verified, show check icon */}
                    {otpVerified || profileDetails?.is_phone_verified == 1 ? (
                      <>
                        <CheckCircle color="#16FF96" size={24} className="ms-2" />
                      </>
                    ) : null}

                  </div>
                  <span className="text-start required">
                    {touched.mobile_no && errors.mobile_no}
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                LinkedIn
              </Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                value={values?.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.linkedin && errors.linkedin}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Facebook
              </Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                value={values?.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.facebook && errors.facebook}
              </span>
            </Form.Group>

            <div className="mt-3 d-flex">
              <Button
                className="btn btn-theme font700 mx-1"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                className="btn btn-theme font700 mx-1 d-none d-md-block"
                onClick={() => backAction()}
              >
                Back
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {loading ? <Loader /> : ""}

      {/** OTP Verification Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="verify-otp-modal"
      >
        <div className="modal-body text-start ">
          <div className="d-flex justify-content-between align-items-center mb-4 ">
            <h4 className="text-start ">Please enter the OTP to verify your account</h4>
            <a onClick={handleClose}
              style={{ top: "10px", right: "20px" }}
              className="position-absolute"
            >
              <CrossIcon />
            </a>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2 ">
            <p>A code has been sent to {phoneNum} </p>
          </div>
          <Modal.Body className="px-0 pb-0 pt-0">
            <Row>
              <Col sm={12} className="mx-auto">
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  placeholder="xxxxxx"
                  containerStyle=" justify-content-center gap-3 otp-input mt-3"
                />
                <div className="text-center">
                  <Link
                    className="font700 d-block mt-3 secondaycolor"
                    onClick={() => handleSendOtp(phoneNum)}
                  >
                    {loading ? "Sending OTP..." : "Resend OTP"}
                  </Link>

                  {/** Hide until resend api request is loading */}
                  {!loading && (
                    <button
                      className="btn btn-theme mt-3 mb-1 w-100"
                      type="submit"
                      onClick={verifyOtp}
                    >
                      Validate OTP
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
