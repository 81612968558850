/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Commons/Footer";
import RenterProfileTabs from "../../Commons/ProfileTabs";
import Paginate from "../../Pagination/Pagination";
import { getSavedProductList, saveProduct } from "../../Services/APIServices";
import {
  CancelRedIcon,
  CrossDarkIcon,
  CrossIcon,
  SearchGrayIcon,
} from "../../SvgIcons/allIcons";

import { useSelector } from "react-redux";
import {
  DEFAULT_CURRENCY,
  LENDER,
  PERPAGE_TEN,
  PERPAGE_THIRTY,
  PERPAGE_TWENTY,
  RENTER,
} from "../../globals/constants";

import ReactStars from "react-stars";
import Nodata from "../../Commons/CommonModals/Nodata";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import { imageBase } from "../../globals/Config";
import useRole from "../../hooks/useRole";

const RenterSavedItems = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoding] = useState(true);
  const role = useRole();
  const checkLoggedInUser = useSelector((state) => state?.Login?.userDetails);

  const [show, setShow] = useState(false);
  const [meta, setMeta] = useState("");
  const [search, setSearch] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setDetails(data);
  };
  const [page, setPage] = useState("1");
  const [details, setDetails] = useState("");
  const [perPage, setPerPage] = useState(PERPAGE_TEN);

  useEffect(() => {
    getSavedProduct(page, search, perPage);
  }, []);

  const [list, setList] = useState([]);
  const getSavedProduct = (page, search = "", perPage = PERPAGE_TEN) => {
    getSavedProductList(page, search, perPage)
      .then((resp) => {
        setLoding(false);
        if (resp?.status === 200) {
          setList(resp?.data?.data);
          setMeta(resp?.data?.meta_data);
        }
      })
      .finally(() => setLoding(false));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    getSavedProduct(page, e.target.value, perPage);
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    getSavedProduct(e.selected + 1, search, perPage);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    getSavedProduct(page, search, e.target.value);
  };

  const Handler = (e) => {
    e.preventDefault();
    let body = {
      product_id: details?.id,
      status: 0,
    };
    let formData = new FormData();
    Object.keys(body).forEach((i) => {
      formData.append(i, body[i]);
    });
    if (checkLoggedInUser) {
      saveProduct(formData).then((resp) => {
        if (resp?.data?.status === 200) {
          setShow(false);
          getSavedProduct();
        }
      });
    } else {
      navigate("/");
    }
  };

  const handleViewProduct = (id) => {
    {
      token
        ? navigate(`/renter/renter-details?id=${id}`)
        : navigate(`/renter-details?id=${id}`);
    }
  };

  const removeSearch = () => {
    getSavedProduct(page, "", perPage);
    setSearch("");
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        {token && <RenterProfileTabs active="saved-item" />}

        <div className="top-breadcrumb top-area mt36">
          <Container>
            <div className="row">
              <Col lg={4} sm={12}>
                <Form className="search-form">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="Search"
                    aria-label="Search"
                    value={search}
                    onChange={handleSearch}
                  />
                  <span className="search-icon">
                    <SearchGrayIcon />
                  </span>
                  {search.length ? (
                    <span className="cross" onClick={removeSearch}>
                      <CrossDarkIcon />
                    </span>
                  ) : (
                    ""
                  )}
                </Form>
              </Col>
            </div>
          </Container>
        </div>
        <div className="accountmain">
          <div className="category-boxes heightauto mt-4 mb-5">
            <Container>
              <Row>
                {list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <Col lg={3} md={4} sm={12} key={index} className="mb-4 ">
                        <Card className="mb-4" style={{ cursor: "pointer" }}>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              handleShow(data);
                            }}
                            className="cancel-icon"
                          >
                            <CancelRedIcon />
                          </Link>
                          <Card.Img
                            onClick={() => handleViewProduct(data?.id)}
                            variant="top"
                            src={
                              data?.product_image?.images
                                ? imageBase + data?.product_image?.images
                                : require("../../assets/images/product1.jpg")
                            }
                          />
                          <Card.Body>
                            <div
                              className="font12 font700 mb-2 d-flex align-items-center gap-3"
                              onClick={() =>
                                navigate(
                                  `/renter/user-profile?user_id=${data?.created_by?.id}`
                                )
                              }
                            >
                              <i>
                                {data?.created_by?.full_name} (
                                {data?.rental_count})
                              </i>

                              <span className="stars-card">
                                <ReactStars
                                  value={data?.average_rating}
                                  count={5}
                                  size={24}
                                  color2={"#16ff96"}
                                  edit={false}
                                />
                              </span>
                            </div>
                            <div onClick={() => handleViewProduct(data?.id)}>
                              <h5 className="head16">{data?.title}</h5>
                              <p className="font700 font12 mb-0">
                                {" "}
                                Today Price :
                                {data?.daily_price
                                  ? ` ${DEFAULT_CURRENCY} ${Number(
                                      data?.daily_price
                                    ).toLocaleString("hi")}`
                                  : ""}
                              </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <>{!loading && <Nodata />}</>
                )}
              </Row>
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                <Paginate
                  page={meta?.page_count}
                  handlePageClick={(e) => handlePageChange(e)}
                  cp={page}
                />
                <div className="d-flex align-items-center justify-content-end flex-wrap gap-2 mb-3">
                  <h6 className="font12 font400 colordark mb-0">Show</h6>
                  <div style={{ width: "70px" }}>
                    <Form.Select
                      aria-label="Default select"
                      className="font14 font400"
                      value={perPage}
                      onChange={handlePerPageChange}
                    >
                      <option value={PERPAGE_TEN}>10</option>
                      <option value={PERPAGE_TWENTY}>20</option>
                      <option value={PERPAGE_THIRTY}>30</option>
                    </Form.Select>
                  </div>
                  <h5 className="font12 font400 colordark mb-0">per page</h5>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Footer />

      {/* delete items modal */}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex delete-head justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete Item from saved items</h4>
            <a className="delete-close" onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="px-0 pb-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-left">
                Do you want remove following item from the list of saved items?
              </p>
            </div>
            <Row>
              <Col lg={10} sm={12} className="mx-auto">
                <div className="product-img">
                  <img
                    src={
                      details?.product_image?.images &&
                      imageBase + details?.product_image?.images
                    }
                  />
                  <div className="product-detail">
                    <div className="d-flex flex-wrap align-items-centers">
                      <p className="font16 font700 mb-0">{`${details?.created_by?.full_name} (${details?.rental_count})`}</p>
                      <span className="stars-card ms-2">
                        <ReactStars
                          value={details?.average_rating}
                          count={5}
                          size={24}
                          color2={"#16ff96"}
                          edit={false}
                        />
                      </span>
                    </div>

                    <h4 className="font16 colordark font700">
                      {details?.title}
                    </h4>
                    <p className="font700 font12 mb-0">
                      Today Price : {DEFAULT_CURRENCY}
                      {Number(details?.daily_price).toLocaleString("hi")}
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-danger mt-3 mb-1 w-100"
                    onClick={(e) => Handler(e)}
                  >
                    Delete
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default RenterSavedItems;
