/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nodata from "../../Commons/CommonModals/Nodata";
import Dropdown from "../../Commons/Dropdown";
import Footer from "../../Commons/Footer";
import Loader from "../../Commons/Loader/Loader";
import { LanderListing } from "../../Services/APIServices";
import { CrossDarkIcon, SearchGrayIcon } from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import {
  ACTIVE_STATE,
  ALL_STATE,
  DRAFT_STATE,
  INACTIVE_STATE,
  PERPAGE_TEN,
} from "../../globals/constants";
const LenderMobileListing = () => {
  const [list, setList] = useState([]);
  const [meta, setMeta] = useState("");
  const [search, setSearch] = useState("");
  const [state, setState] = useState(ACTIVE_STATE);
  const [page, setPage] = useState("1");
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    productListing(search, state, page, perPage);
  }, []);

  const productListing = (
    search = "",
    state = ACTIVE_STATE,
    page,
    perPage = PERPAGE_TEN
  ) => {
    LanderListing(search, state, page, (perPage = PERPAGE_TEN))
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.status === 200) {
          setList(resp?.data?.data);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    productListing(e.target.value, state?.value, page, perPage);
  };

  const removeSearch = () => {
    productListing("", state?.value, page, perPage);
    setSearch("");
  };

  const handleStateChange = (e) => {
    setState(e);
    productListing(search, e?.value, page, perPage);
  };

  return (
    <>
      <div className="top-breadcrumb top-area bg-white">
        <Container>
          <Row className="align-items-center">
            <Col lg={9}>
              <Row>
                <Col lg={12}>
                  <Form className="search-form card-search">
                    <Form.Label className="mb-0">Search</Form.Label>
                    <Form.Control
                      placeholder="Search Listing "
                      className="Search"
                      aria-label="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                    <span className="search-icon">
                      <SearchGrayIcon />
                    </span>
                    {search.length ? (
                      <span className="cross" onClick={removeSearch}>
                        <CrossDarkIcon />
                      </span>
                    ) : (
                      ""
                    )}
                  </Form>
                </Col>
                <Col lg={12}>
                  <Form.Label className="mb-0">Status</Form.Label>
                  <Dropdown
                    name="my_rental"
                    value={state}
                    clearable={false}
                    options={[
                      { label: "All", value: ALL_STATE },
                      { label: "Active", value: ACTIVE_STATE },
                      { label: "Inactive", value: INACTIVE_STATE },
                      { label: "Draft", value: DRAFT_STATE },
                    ]}
                    placeholder={"Select"}
                    onChange={(e) => handleStateChange(e)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="accountmain mobile-list mb-5 mt-0">
        <Container>
          <Row>
            {list && list.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <Col lg={3} md={4} sm={12} key={index}>
                    <Link to={`/lender/new-item?atab=first&rid=${data?.id}`}>
                      <div className="listcard">
                        <div>
                          <img
                            className="card-img-top"
                            src={
                              data?.product_image?.images
                                ? imageBase + data?.product_image?.images
                                : require("../../assets/images/static_image.jpg")
                            }
                            alt="img"
                          />
                        </div>
                        <div>
                          <h5 className="head16">{data?.title}</h5>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })
            ) : (
              <>{!loading && <Nodata />}</>
            )}
          </Row>
          {list?.length > 0 ? (
            <Col lg={3} md={3} sm={12} className="text-end">
              <Link
                className="btn btn-default-custom mb-0 d-block d-lg-none text-start"
                to="/lender/new-item"
              >
                <span className="plus_">+</span> List another item
              </Link>
            </Col>
          ) : (
            <Col lg={3} md={3} sm={12} className="text-end">
              <Link
                className="btn btn-default-custom mb-0 d-block d-lg-none text-start"
                to="/lender/new-item"
              >
                <span className="plus_">+</span> List item
              </Link>
            </Col>
          )}
        </Container>
      </div>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default LenderMobileListing;
