/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeFooter from "../Commons/HomeFooter";
import {
  getCategoryHome,
  getSlideCategory,
  getStaticPages,
} from "../Services/APIServices";
import {
  ArrowrightIcon,
  BackGreenIcon,
  ParkGreenIcon,
  SearchIcon,
} from "../SvgIcons/allIcons";
import { truncate } from "../utils/CommonFunction";
import {
  HOME_PAGE_CATEGORY_LIMIT,
  HOW_IT_WORKS,
  LENDER,
  RENTER,
} from "../globals/constants";
import useDocumentTitle from "../globals/useDocumentTitle";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Header from "../Commons/Headers/Header";
import useRole from "../hooks/useRole";
import { imageBase } from "../globals/Config";

const Home = () => {
  useDocumentTitle("SHAXIFY - Home");
  const navigate = useNavigate();
  const nextRef = useRef();
  const prevRef = useRef();
  const role = useRole();
  const [banner, setBanner] = useState([]);
  const [list, setList] = useState([]);
  const [howitWorks, setHowItWorks] = useState("");

  useEffect(() => {
    categoryList();
    getSlideList();
    howItWorks();
  }, []);

  const categoryList = () => {
    getCategoryHome("", HOME_PAGE_CATEGORY_LIMIT).then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.data);
      }
    });
  };

  const getSlideList = () => {
    getSlideCategory().then((resp) => {
      if (resp?.status === 200) {
        setBanner(resp?.data?.data);
      }
    });
  };

  const handleViewBaner = (data) => {
    navigate(`/catagory-mapping?id=${data?.category}&t=${data?.category_name}`);
  };

  const handleViewCategory = (data) => {
    navigate(`/catagory-mapping?id=${data?.id}&t=${data?.title}`);
  };

  const howItWorks = () => {
    getStaticPages(HOW_IT_WORKS).then((resp) => {
      if (resp?.data?.status === 200) {
        setHowItWorks(resp?.data?.data);
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="main-slider">
          <div className="hero-banner">
            <Container>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                }}
                pagination={{
                  el: ".swiper-custom-pagination",
                  renderBullet: (index, className) => {
                    return `<div className=${className}></div>`;
                  },
                  clickable: true,
                }}
                loop={true}
                autoplay={true}
                className="pb-lg-5"
              >
                {banner &&
                  banner?.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <Row className="align-items-center">
                          <Col lg={6} md={6} sm={12}>
                            <div className="slide-content px-3">
                              <h2>
                                <i>{truncate(data?.title, 50)}</i>
                              </h2>
                              <p>{truncate(data?.description, 200)}</p>
                              <Button
                                className="btn btn-theme"
                                onClick={() => handleViewBaner(data)}
                              >
                                Take me there
                              </Button>
                            </div>
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <img
                              src={
                                data?.image
                                  ? imageBase + data?.image
                                  : require("../assets/images/DummyImg.png")
                              }
                              alt="slide1"
                            />
                          </Col>
                        </Row>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <div className="bannr-navigators">
                <div className="prev-btn" ref={prevRef}/>
                <div className="swiper-custom-pagination" />
                <div className="next-btn" ref={nextRef}/>
              </div>
            </Container>
          </div>
          <Container>
            {/* Category List */}

            <div className="category-boxes">
              <Row>
                {list &&
                  list.length > 0 &&
                  list?.map((data, index) => {
                    return (
                      <Col lg={3} md={4} sm={12} key={index}>
                        <Card
                          onClick={() => handleViewCategory(data)}
                          style={{ cursor: "pointer" }}
                        >
                          <Card.Img
                            variant="top"
                            src={
                              data?.category_image &&
                              imageBase + data?.category_image
                            }
                          />
                          <Card.Body>
                            <Card.Title>
                              {data?.title ? data?.title : "NA"}
                            </Card.Title>
                            <Card.Text>
                              {data?.description ? data?.description : "NA"}
                            </Card.Text>
                            <Link className="btn btn-shop ps-0">
                              Shop Now! <ArrowrightIcon />
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </Container>
        </div>

        <div className="how-it-work ptb">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6} sm={12}>
                <div className="pe-lg-5 pe-0 me-lg-5 me-0">
                  <h4 className="font24 text-start">How it works</h4>
                  <h2>{howitWorks?.title}</h2>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: howitWorks?.content,
                    }}
                  />
                  <Link to="/how-it-works" className="btn btn-theme">
                    Learn More
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <img
                  src={require("../assets/images/how-it-work-img.png")}
                  alt="img"
                />
              </Col>
            </Row>
            <div className="boxes">
              <Row>
                <Col lg={4}>
                  <span className="search">
                    <SearchIcon />
                  </span>
                  <h4>Find an item you need</h4>
                </Col>
                <Col lg={4}>
                  <span>
                    <ParkGreenIcon />
                  </span>
                  <h4>Get it shipped to you</h4>
                </Col>
                <Col lg={4}>
                  <span>
                    <BackGreenIcon />
                  </span>
                  <h4>Return after you’re done</h4>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="itmes-list ptb">
          <Container>
            <Row className="align-items-center py-lg-5">
              <Col lg={6} md={6} sm={12}>
                <h4 className="text-white ">Want to make extra cash?</h4>
                <h2 className="text-white">
                  With a shared economy you are giving each product a new life
                </h2>
                <Button
                  className="btn btn-theme mt-3"
                  onClick={() => {
                    document?.getElementById("headerlogin").click();
                  }}
                >
                  List your item
                </Button>
              </Col>
            </Row>
            <div className="category-boxes list-boxes">
              <Row>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../assets/images/img1.jpg")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Get your rental delivered to your door steps</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../assets/images/img2.jpg")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Secure online payments</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../assets/images/img3.jpg")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Insurance coverage is on us</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../assets/images/img4.jpg")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Support any problems</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Home;
