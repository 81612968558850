import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isLogin, isLogout } from "../../Redux/LoginSlice";
import { ChangeUserPassword, DeleteAccount } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { convertUTCToLocal } from "../../Services/helper";
import {
  BackIcon,
  ClosedEyeGreyIcon,
  CrossIcon,
  EyeGreyIcon,
} from "../../SvgIcons/allIcons";
import { Selected } from "../../App";
import Loader from "../Loader/Loader";

const MobileSetting = ({ isSelected }) => {
  const { setIsSelected } = useContext(Selected);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordChangedTime = useSelector(
    (state) => state?.Login?.userDetails?.created_on
  );
  const [showCurrentPassword, setShowCurrentPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const validationSchema = Yup.object({
    current_password: Yup.string().when(["selectionType"], {
      is: (selectionType) => {
        return selectionType ? true : false;
      },
      then: () => Yup.string().required("Current Password is required").trim(),
    }),
    new_password: Yup.string().when(["selectionType"], {
      is: (selectionType) => {
        return selectionType ? true : false;
      },
      then: () =>
        Yup.string()
          .required("New password required")
          .min(
            8,
            "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
          )
          .trim()
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
          ),
    }),

    confirm_password: Yup.string().when(["selectionType"], {
      is: (selectionType) => {
        return selectionType ? true : false;
      },
      then: () =>
        Yup.string()
          .required("Please confirm password")
          .trim()
          .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    }),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    touched,
    setValues,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: {
      selectionType: null,
      select_type: 1,
      current_password: "",
      new_password: "",
      confirm_password: "",
      email_type: "",
      text_type: "",
      promotion_type: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      if (values.current_password && values.current_password !== "") {
        setSubmitting(true);
        let formData = new FormData();
        formData.append("select_type", 1);
        formData.append("current_password", values.current_password);
        formData.append("new_password", values?.new_password);
        setLoading(true);
        ChangeUserPassword(formData).then((resp) => {
          setLoading(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            resetForm();
          } else {
            showNotification("danger", resp?.data?.message);
          }
        });
      } else {
        setSubmitting(true);
        let formData = new FormData();
        formData.append(" select_type", 2);
        formData.append("email_type", values?.email ? "1" : "0");
        formData.append("text_type", values?.text ? "1" : "0");
        formData.append("promotion_type", values?.promotion ? "1" : "0");
        setLoading(true);
        ChangeUserPassword(formData).then((resp) => {
          setLoading(false);
          setIsSelected(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            resetForm();
            dispatch(isLogin(resp?.data?.data));
          }
        });
      }
    },
  });

  const userDetails = useSelector((state) => state?.Login?.userDetails);
  useEffect(() => {
    setValues({
      email: userDetails?.email_notification,
      text: userDetails?.text_notification,
      promotion: userDetails?.recieve_promotions_notification,
    });
  }, [userDetails, isSelected]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteAccount = () => {
    DeleteAccount().then((resp) => {
      if (resp?.status === 200) {
        localStorage.clear();
        navigate("/");
        dispatch(isLogout());
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  return (
    <>
      <p className="head16 d-none d-lg-block d-md-block">Password</p>
      <p className="head16 d-flex d-lg-none d-md-none align-items-center gap-3">
        <Link
          className="d-block d-lg-none d-md-none"
          onClick={(e) => {
            e.preventDefault();
            setIsSelected(false);
          }}
        >
          <BackIcon />
        </Link>
        Settings
      </p>

      <Row>
        <Col lg={4} md={6}>
          <Form className="change-password">
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                Current Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showCurrentPassword ? "text" : "password"}
                name="current_password"
                value={values?.current_password}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldValue("selectionType", true);
                }}
              />
              <div
                className="eye-icon"
                onClick={() => setShowCurrentPassword((prev) => !prev)}
              >
                {showCurrentPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.current_password && errors?.current_password && (
                <span className="text-start required">
                  {errors?.current_password}
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                New Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                value={values?.new_password}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  setFieldValue("selectionType", true);
                }}
              />
              <div
                className="eye-icon"
                onClick={() => setShowNewPassword((prev) => !prev)}
              >
                {showNewPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.new_password && errors?.new_password && (
                <span className="text-start required">
                  {errors?.new_password}
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                Confirm Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={values?.confirm_password}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);

                  setFieldValue("selectionType", true);
                }}
              />
              <div
                className="eye-icon"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.confirm_password && errors?.confirm_password && (
                <span className="text-start required">
                  {errors?.confirm_password}
                </span>
              )}
            </Form.Group>
            <Button
              className="btn btn-theme font700 w-100 mb-3 d-none d-lg-block d-md-block"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              Update
            </Button>
            {passwordChangedTime && (
              <p className="last-update font12 font700 text-dark">
                Last updated{" "}
                {moment(convertUTCToLocal(passwordChangedTime)).fromNow()}
              </p>
            )}
          </Form>
        </Col>
      </Row>

      <p className="head16">Notifications</p>
      <p className="head12">
        Receive notifications related to your rentals, requests and bookings
      </p>

      <Row>
        <Col lg={4} md={6}>
          <Form className="notification">
            <div className="mb-2">
              <Form.Check
                label="Emails"
                id="Emails"
                type="checkbox"
                name="email"
                value={values?.email}
                checked={values?.email}
                onChange={handleChange}
                onBlur={(e) => {
                  setFieldValue("selectionType", false);
                  handleBlur(e);
                }}
              />
            </div>
            <div className="mb-2">
              <Form.Check
                label="Texts"
                id="Texts"
                type="checkbox"
                name="text"
                value={values?.text}
                checked={values?.text}
                onChange={handleChange}
                onBlur={(e) => {
                  setFieldValue("selectionType", false);
                  handleBlur(e);
                }}
              />
            </div>
            <div>
              <Form.Check
                label="Want to receive promotions"
                id="Want to receive promotions"
                type="checkbox"
                name="promotion"
                value={values?.promotion}
                checked={values?.promotion}
                onChange={handleChange}
                onBlur={(e) => {
                  setFieldValue("selectionType", false);
                  handleBlur(e);
                }}
              />
            </div>
            <Button
              className="btn btn-theme font700 w-50 mt-3 d-none d-lg-block d-md-block"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Form>
        </Col>
      </Row>

      <p className="head16 d-none d-lg-block d-md-block">Account</p>
      <Row>
        <Col lg={4} md={6}>
          <Button
            onClick={handleShow}
            className="btn btn-danger font700 d-none d-lg-block d-md-block"
          >
            Delete Account
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6} className="mt-4">
          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className="btn btn-theme font700 d-block d-lg-none d-md-none w-100"
          >
            Save
          </Button>
          <Button
            onClick={handleShow}
            className="btn btn-danger font700 d-block d-lg-none d-md-none w-100"
          >
            Delete Account
          </Button>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="smallmodal"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete Account</h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="p-0">
            <div className="mb96">
              <p className="head12">
                Are you sure you want to delete your account? You’ll loose
                access to your all your rentals.
              </p>
              <p className="head12">
                Account deletion is only possible if you do not have any ongoing
                rentals
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <Button
                  className="btn btn-theme-outline font700 w-100 height42"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6">
                <Button
                  className="btn btn-danger font700 w-100 height42"
                  onClick={handleDeleteAccount}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {loading ? <Loader /> : null}
    </>
  );
};

export default MobileSetting;
