/*
@copyright : Ozvid Technologies Pvt. Ltd. < www.ozvid.com >
@author     : Shiv Charan Panjeta < shiv@ozvid.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of Ozvid Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/

import { PERPAGE_TWENTY } from "../globals/constants";
import * as api from "../utils/requests";

export const SignUpAPI = async (body) => {
  return await api.POSTReq(`/api/signup/`, body);
};

export const LogUpApi = async (body) => {
  return await api.POSTReq(`/api/login/`, body);
};

export const categoryItemsList = async (
  id = "",
  title = "",
  from = "",
  to = "",
  sort_by = "",
  status = "",
  page = "1",
  perPage = "10",
  map_view
) => {
  return await api.getReq(
    `/api/product-listing/?category_id=${id}&title=${title}&from=${from}&to=${to}&sort_by=${sort_by}&status=${status}&page=${page}&per_page=${perPage}&map_view=${map_view}`
  );
};
export const UserDetails = async () => {
  return await api.GETReq(`/api/profile-details/`);
};

export const UpdateUserDetails = async (body) => {
  return await api.POSTReq(`/api/users-profile-update/`, body);
};

export const UpdateContactInfo = async (body) => {
  return await api.POSTReq(`/api/contact-information/`, body);
};

export const logOut = async () => {
  return await api.GETReq("/api/logout/");
};

export const Forgetpassword = async (body) => {
  return await api.POSTReq(`/api/forgot-password/`, body);
};

export const getCategory = async (page = 1, limit = 0) => {
  return await api.GETReq(`/api/category-listing/?page=${page}&limit=${limit}`);
};

export const getCategoryHome = async (page = 1, per_page, limit = 0) => {
  return await api.GETReq(
    `/api/category-listing/?page=${page}&per_page=${per_page}&limit=${limit}`
  );
};

export const getSavedProductList = async (
  page = "",
  search = "",
  perPage = ""
) => {
  return await api.GETReq(
    `/api/saved-products-list/?page=${page}&title=${search}&per_page=${perPage}`
  );
};

export const getSubCategory = async (categoryId, page) => {
  return await api.GETReq(
    `/api/product-subcategory/?page=${page}&category_id=${categoryId}`
  );
};

export const getSlideCategory = async () => {
  return await api.GETReq("/api/banner/");
};

export const getAddressList = async () => {
  return await api.GETReq("/api/address-listing/");
};

export const viewAddress = async (id) => {
  return await api.getReq("/api/get-address-details/?product_id=" + id);
};

export const productDetailForCheckout = async (body) => {
  return await api.POSTReq(`/api/product-details-for-checkout/`, body);
};

export const productDetailAtCheckout = async (product_id) => {
  return await api.getReq(
    `/api/get-checkout-details/?product_id=${product_id}`
  );
};

export const CheckUserLogin = async () => {
  return await api.getReq("/api/check-user/");
};

export const ChangeUserPassword = async (body) => {
  return await api.POSTReq(
    `/api/change-password-and-notifications-type/`,
    body
  );
};

export const addToCart = async (body) => {
  return await api.POSTReq(`/api/add-product-to-cart/`, body);
};

export const CheckOut = async (body) => {
  return await api.POSTReq(`/api/checkout/`, body);
};

export const DeleteAccount = async () => {
  return await api.POSTReq(`/api/delete-account/`);
};
export const AddAddress = async (body) => {
  return await api.POSTReq(`/api/address-information/`, body);
};
export const AddCard = async (body) => {
  return await api.POSTReq(`/api/add-card/`, body);
};

export const ShowCardList = async () => {
  return await api.getReq(`/api/card-listing/`);
};

export const DeleteCard = async (body) => {
  return await api.POSTReq(`/api/delete-card/`, body);
};

export const updateAddress = async (body) => {
  return await api.POSTReq(`/api/update-user-address/`, body);
};

export const getStaticPages = async (type) => {
  return await api.GETReq(`/api/static-page/?page_id=${type}`);
};

export const deleteAddress = async (body) => {
  return await api.POSTReq(`/api/delete-address/`, body);
};

export const notificationPreference = async (body) => {
  return await api.POSTReq(`/api/select-notification-type/`, body);
};

/************************User Role Change******************/
export const ChangeCurrentRole = async (body) => {
  return await api.POSTReq(`/api/switch-roles/`, body);
};

/*************************Add Product *********************/
export const addProductInformation = async (body) => {
  return await api.POSTReq(`/api/add-product-information/`, body);
};

export const addProductImages = async (body) => {
  return await api.POSTReq(`/api/product-photos/`, body);
};
export const editProductImages = async (body) => {
  return await api.POSTReq(`/api/edit-product-image/`, body);
};

export const LanderListing = async (
  search = "",
  state = "",
  page = "",
  perPage = "",
  map_view = false
) => {
  return await api.getReq(
    `/api/product-listing/?title=${search}&status=${state}&page=${page}&per_page=${perPage}&map_view=${map_view}`
  );
};

export const calenderProducts = async (page = "", perPage = "") => {
  return await api.getReq(
    `/api/calender-products-listings/?page=${page}&per_page=${perPage}`
  );
};

export const myRentelListing = async (
  search = "",
  rentalType = "",
  page = "",
  perPage = ""
) => {
  return await api.getReq(
    `/api/my-rentals/?search=${search}&rental_type=${rentalType}&page=${page}&per_page=${perPage}`
  );
};

export const LanderPhotos = async (product_id) => {
  return await api.getReq(
    `/api/product-images-listing/?product_id=${product_id}`
  );
};

/*************************Add Peicing and Availability  *********************/

export const AddPricing = async (body) => {
  return await api.POSTReq(`/api/product-pricing-and-rental-period/`, body);
};

export const AddFee = async (body) => {
  return await api.POSTReq(`/api/product-cleaning-and-tax/`, body);
};

export const AddDiscount = async (body) => {
  return await api.POSTReq(`/api/product-discount/`, body);
};

export const deleteProductImage = async (body) => {
  return await api.POSTReq(`/api/delete-product-image/`, body);
};

export const addProductExtraInfo = async (body) => {
  return await api.POSTReq(`/api/product-extra-information/`, body);
};

export const getProductDetails = async (product_id) => {
  return await api.GETReq(`/api/product-details/?product_id=${product_id}`);
};

export const getProductImageDetails = async (product_id) => {
  return await api.GETReq(
    `/api/view-rental-product-images/?order_id=${product_id}`
  );
};

export const getProductDetailsAfterCheckout = async (product_id) => {
  return await api.GETReq(
    `/api/product-details-after-checkout/?order_id=${product_id}`
  );
};

export const addProductLocation = async (body) => {
  return await api.POSTReq(`/api/product-address/`, body);
};

export const AddProductAvailability = async (body) => {
  return await api.POSTReq(`/api/product-availability/`, body);
};

export const LanderProductDetails = async (product_id) => {
  return await api.getReq(`/api/product-details/?product_id=${product_id}`);
};

export const deleteProduct = async (body) => {
  return await api.POSTReq(`/api/delete-product/`, body);
};

export const saveProduct = async (body) => {
  return await api.POSTReq(`/api/save-unsave-product/`, body);
};

export const addRating = async (body) => {
  return await api.POSTReq(`/api/rating/`, body);
};

export const ratingDetails = async (id = "") => {
  return await api.getReq(`/api/get-rating-details/?id=${id}`);
};

export const productAvailabilityDetails = async (product_id, month = "") => {
  return await api.getReq(
    `/api/availability-details/?product_id=${product_id}&month=${month}`
  );
};
export const addSupport = async (body) => {
  return await api.POSTReq(`/api/support/`, body);
};

export const reservedProduct = async (
  page = "",
  perPage = "",
  search = "",
  search_title = ""
) => {
  return await api.getReq(
    `/api/reserved-products/?page=${page}&per_page=${perPage}&search=${search}&search_title=${search_title}`
  );
};

// left side message

export const chatListApi = async (search = "", page = "", perPage = "") => {
  return await api.getReq(
    `/api/chats-list/?search=${search}&page=${page}&per_page=${perPage}`
  );
};

export const loadNewMessage = async (body) => {
  return await api.POSTReq(`/api/load-messages/`, body);
};

export const sendMessage = async (body) => {
  return await api.POSTReq(`/api/send-message/`, body);
};

export const MessageWindowApi = async (body, page = 1) => {
  return await api.POSTReq(
    `/api/message-window/?page=${page}&per_page=${PERPAGE_TWENTY}`,
    body
  );
};

export const RenterSummaryApi = async (id) => {
  return await api.getReq(`/api/rental-summary/?order_id=${id}`);
};

export const productStatusAPi = async (body) => {
  return await api.POSTReq(`/api/product-status/`, body);
};

export const reportUserApi = async (body) => {
  return await api.POSTReq(`/api/report-users/`, body);
};

export const productImages = async (body) => {
  return await api.POSTReq(`/api/rental-product-pictures/`, body);
};

export const productLabel = async (id) => {
  return await api.getReq(`/api/get-shipping-label/?order_id=${id}`);
};
/************************Shaxify News Letter ******************/
export const NewsLetter = async (body) => {
  return await api.POSTReq(`/api/newsletter-subscription/`, body);
};

export const AddBank = async (code) => {
  return await api.getReq(`/api/add-bank-account/?account_code=${code}`);
};

export const getAllBank = async () => {
  return await api.getReq(`/api/get-bank-account/`);
};

export const getPriceEstimation = async (body) => {
  const codValue = body?.cod != null ? body.cod : 0;
  return await api.getReq(
    `/api/get-product-amount-details/?product_id=${body.product_id}&from=${body.from}&to=${body.to}&deliver_to=${body.deliver_to}&is_cod=${codValue}&address_id=${body?.address_id ? body?.address_id : ""}`
  );
};

export const getCartData = async (page = "") => {
  return await api.getReq(`/api/cart-items/?page=${page}&per_page=10`);
};
export const removeCartProduct = async (body) => {
  return await api.POSTReq(`/api/remove-product-from-cart/`, body);
};

export const availavlityDetailsPerDay = async (product_id, month, year) => {
  return await api.GETReq(
    `/api/per-day-availability-details/?product_id=${product_id}&month=${month}&year=${year}`
  );
};

export const getUserProfileDetails = async (id, page = 1) => {
  return await api.GETReq(`/api/view-user-profile/?user_id=${id}&page=${page}`);
};

export const userProductListing = async (id) => {
  return await api.GETReq(`/api/user-products/?user_id=${id}`);
};

export const dragDropImage = async (body) => {
  return await api.POSTReq("/api/change-product-images-order/", body);
};

export const addBankNew = async (body) => {
  return await api.POSTReq("/api/add-bank-account/", body);
};

export const deleteBank = async (id) => {
  return await api.getReq(`/api/delete-bank-account/?account_id=${id}`);
};

export const socialLogin = async (body) => {
  return await api.POSTReq(`/api/social-google-facebook/`, body);
};

export const actionDeliveryRequest = async (body) => {
  return await api.POSTReq("/api/action-delivery-request/", body);
};